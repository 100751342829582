@import './_vars.scss';

.parking_vector1 {
    .background-line {
        position: absolute;
        top: 535px;
        width: 100%;
        left: -10%;
        right: 0;
        z-index: 0;

        @media (max-width: 1639px) {
            top: 200px;
        }

        @media (max-width: 1279px) {
            top: 90px;
        }

        @media (max-width: 1279px) {
            top: -100px;
        }

        @media (max-width: 767px) {
            top: 75px;
        }

        .path {
            stroke-dasharray: 5;
            -webkit-animation: dash 40s linear;
            animation: dash 40s linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }

        :not(svg) {
            transform-origin: 0px 0px;
        }

        @keyframes dash {
            100% {
                stroke-dashoffset: 1000;
            }
        }
    }
}

section .parking_hero {
    padding-top: 0px;
}

.parking_hero {
    padding: 0;
    position: relative;
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (max-width: 1439px) {
        height: auto;
    }

    .cont {
        margin: 0;

        @media (max-width: 767px) {
            width: 100%;
            margin: 0 auto;
            padding: 0;
        }
    }

    .content-wrap {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: start;
        max-width: 872px;
        box-sizing: border-box;
        text-align: start;
        float: left;
        padding: 138px 0 172px;
        margin-left: 90px;

        @media (max-width: 1800px) {
            margin-left: 0;
        }

        @media (max-width: 1537px) {
            padding: 168px 0 172px;
        }

        @media (max-width: 1439px) {
            padding-top: 171px;
            max-width: 720px;
        }

        @media (max-width: 1200px) {
            max-width: 555px;
        }

        @media (max-width: 1023px) {
            max-width: 430px;
            padding: 98px 0 211px;
        }

        @media (max-width: 767px) {
            max-width: 767px;
            width: 100%;
            padding: 33px 0 606px;
            margin: 0 auto;
        }

        .heroZoomIn.yellow {
            color: $yellow;
            margin: 0;
            text-shadow: 10px 10px 0px rgba(0, 0, 0, 100%);

            @media (max-width: 1023px) {
                text-shadow: 4px 4px 0px rgba(0, 0, 0, 25%);
            }
        }

        h1 {
            padding-bottom: 0;
            margin: 0;

            @media (max-width: 1700px) {
                font-size: 58px;
                line-height: 70px;
            }

            @media (max-width: 1537px) {
                font-size: 74px;
                line-height: 88px;
            }

            @media (max-width: 1439px) {
                font-size: 54px;
                line-height: 68px;
            }

            @media (max-width: 1279px) {
                font-size: 42px;
                line-height: 50px;
            }

            @media (max-width: 1023px) {
                @include font-size(2.25, 1.194, 0);
                padding-bottom: 0;
            }

            @media (max-width: 767px) {
                font-size: 1.875rem;
                text-align: center;
            }

            span {
                position: relative;
                white-space: nowrap;
            }
        }

        .parking_href-section {
            padding-top: 22px;
            color: $white;
            display: inline-flex;
            max-width: 503px;

            @media (max-width: 1279px) {
                max-width: 442px;
            }

            @media (max-width: 1023px) {
                padding-top: 16px;
                max-width: 326px;
            }

            @media (max-width: 767px) {
                max-width: 100%;
            }

            p,
            img {
                display: inline;
                letter-spacing: 0.03em;
            }

            p {
                @media (max-width: 1700px) {
                    @include font-size(1.188, 1.313, 0);
                }

                @media (max-width: 1279px) {
                    @include font-size(1, 1.125, 0);
                }

                @media (max-width: 1023px) {
                    @include font-size(0.875, 1.5, 0);
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .parking_logo-partner {
                max-width: 125px;
                vertical-align: middle;
                padding-bottom: 3px;

                @media (max-width: 1023px) {
                    max-height: 23px;
                    max-width: 85px;
                }
            }
        }

        .btn {
            top: 82%;
            padding: 22px 49px 23px 50px;
            font-size: 16px;
            position: absolute;

            @media (max-width: 1439px) {
                max-width: 245px;
                padding: 1.35em 1.6em;
            }

            @media (max-width: 1023px) {
                max-width: 204px;
                top: 65%;
                padding: 1.15em 1.15em;
            }

            @media (max-width: 767px) {
                max-width: 204px;
                top: 85%;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    .parking_bottom_subtitle {
        font-size: 1.125rem;
        color: $grey;
        text-align: center;
        margin: 0 auto;
        z-index: 1;

        @media (max-width: 1023px) {
            font-size: 14px;
        }

        img {
            max-width: 16px;
            display: inline;
            margin: 0 auto 0 -1%;
            padding: 0 8px 7px 0;

            @media (max-width: 1439px) {
                max-width: 13px;
            }
        }

        a {
            color: $grey;
            font-size: 1.125rem;
            text-decoration: underline;

            @media (max-width: 1439px) {
                font-size: 16px;
                line-height: 19px;
            }

            @media (max-width: 1023px) {
                font-size: 14px;
            }
        }
    }

    .parking_back {
        .parking_img-wrap {
            position: absolute;
            z-index: 1;

            &.parking_mobile {
                max-width: 326px;
                height: 671px;
                right: 13.7%;
                top: 5%;
                z-index: 7;

                -webkit-animation: bounce-down 5s linear infinite;
                animation: bounce-down 5s linear infinite;

                @media (max-width: 1439px) {
                    width: 20%;
                    top: 9%;
                    right: 17%;
                }

                @media (max-width: 1023px) {
                    max-width: 230px;
                    width: 21%;
                    right: 15%;
                    top: 0;
                }

                @media (max-width: 767px) {
                    max-width: 213px;
                    width: 100%;
                    margin: 0 auto;
                    top: 22%;
                    left: 0;
                    right: 0;
                }
            }

            &.parking_map {
                z-index: 0;
                max-width: 915px;
                width: 100%;
                right: 0;
                top: -116px;

                @media (max-width: 1439px) {
                    width: 60%;
                }

                @media (max-width: 1023px) {
                    max-width: 834px;
                    top: -90px;
                }

                @media (max-width: 767px) {
                    max-width: 1920px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    margin: 0 auto;
                    text-align: center;
                }

                @media (max-width: 660px) {
                    width: 125%;
                    left: 46%;
                }

                @media (max-width: 560px) {
                    top: -95px;
                    width: 150%;
                    left: 45%;
                }

                @media (max-width: 460px) {
                    width: 200%;
                    left: 45%;
                }

                @media (max-width: 376px) {
                    top: -120px;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: transparent;
                }

                img {
                    opacity: 1;
                }
            }

            @-webkit-keyframes bounce-down {
                25% {
                    -webkit-transform: translateY(-4px);
                }

                50%,
                100% {
                    -webkit-transform: translateY(0);
                }

                75% {
                    -webkit-transform: translateY(4px);
                }
            }

            @keyframes bounce-down {
                25% {
                    transform: translateY(-4px);
                }

                50%,
                100% {
                    transform: translateY(0);
                }

                75% {
                    transform: translateY(4px);
                }
            }
        }
    }
}
