@import './_vars.scss';

.parking_background_service-first {
    background-image: url(../img/parking/parking_bg_services.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 1208px 1352px;
    background-position-y: 84%;

    @media (max-width: 767px) {
        background: none;
    }
}

.parking_background_service-second {
    background-image: url(../img/parking/parking_bgrnd_services.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 520px 596px;
    background-position-y: 40%;

    @media (max-width: 1023px) {
        background-size: 430px 596px;
    }

    @media (max-width: 767px) {
        background: none;
    }
}

.parking_services {
    position: relative;
    padding: 248px 0 210px;
    background-size: 250px 1170px;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;

    @media (max-width: 1537px) {
        padding: 255px 0 210px;
    }

    @media (max-width: 1439px) {
        padding: 8.25rem 0 7rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 8.25rem 0 4.7rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 7.65rem 0 0;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0 0;
    }

    h2 {
        @media (max-width: 1439px) {
            margin-top: -5px;
        }

        @media (max-width: 1023px) {
            margin-top: 0px;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 100px 140px;
        justify-content: space-between;
        margin-top: 80px;
        padding-left: 89px;
        padding-bottom: 25px;

        @media (max-width: 1840px) {
            grid-gap: 80px 30px;
        }

        @media (max-width: 1439px) {
            margin-left: 0;
            padding-left: 70px;
            margin-top: 80px;
            grid-gap: 74px 103px;
        }

        @media (max-width: 1320px) {
            margin-left: 0;
            margin-top: 80px;
            grid-gap: 0 60px;
        }

        @media (max-width: 1023px) {
            grid-gap: 30px 30px;
            padding-left: 0;
            margin-top: 50px;
        }

        @media (max-width: 767px) {
            grid-gap: 50px 30px;
            grid-template-columns: repeat(1, 1fr);
            margin-top: 45px;
        }

        .img-wrap {
            box-sizing: border-box;

            @media (max-width: 1023px) {
                max-height: 36px;
                margin-left: 0;
            }

            @media (max-width: 767px) {}

            img {
                max-width: 91.76px;
                border-radius: 50%;
                box-shadow: 5px 5px $black;

                @media (max-width: 1439px) {
                    max-width: 78px;
                }

                @media (max-width: 1023px) {
                    max-width: 63px;
                }
            }
        }

        .text-cont {
            margin-top: 29px;
            display: block;

            @media (max-width: 1439px) {
                margin-top: 28px;
            }

            @media (max-width: 1023px) {
                margin-top: 45px;
            }

            @media (max-width: 767px) {
                margin-top: 52px;
            }

            .servise-title {
                display: inline-flex;

                @media (max-width: 1480px) {
                    display: block;
                }

                @media (max-width: 1023px) {
                    display: inline-flex;
                }

                @media (max-width: 900px) {
                    display: block;
                }

                @media (max-width: 767px) {
                    display: inline-flex;
                }

                .parking_services_logo-uk {
                    max-width: 120px;
                    padding-bottom: 1px;

                    @media (max-width: 1024px) {
                        max-width: 91px;
                        padding-bottom: 0;
                        margin-top: -3px;
                    }

                    @media (max-width: 767px) {
                        max-width: 91px;
                    }
                }

                .parking_services_logo-en {
                    max-width: 160px;

                    @media (max-width: 1024px) {
                        max-width: 113px;
                        margin-top: -1px;
                    }
                }

                p {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 29px;

                    @media (max-width: 1660px) {
                        font-size: 20px;
                    }

                    @media (max-width: 1380px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1023px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }

            .servise-subtitle {
                margin-top: 10px;
                max-width: 359px;

                @media (max-width: 1439px) {
                    max-width: 100%;
                    margin-top: 7px;
                }

                p {
                    font-size: 20px;
                    line-height: 29px;

                    @media (max-width: 1620px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1023px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }
            }
        }
    }

    .parking_video_container {
        display: inline-flex;
        width: 323px;
        float: right;
        margin-top: 75px;
        margin-right: 48px;

        @media (max-width: 1639px) {
            margin-right: 83px;
        }

        @media (max-width: 1600px) {
            margin-right: 70px;
        }

        @media (max-width: 1550px) {
            margin-right: 55px;
        }

        @media (max-width: 1537px) {
            margin-right: 47px;
        }

        @media (max-width: 1500px) {
            margin-right: 37px;
        }

        @media (max-width: 1469px) {
            margin-right: 26px;
        }

        @media (max-width: 1439px) {
            margin-right: -26px;
        }

        @media (max-width: 1367px) {
            margin-right: -49px;
        }

        @media (max-width: 1334px) {
            margin-right: -60px;
        }

        @media (max-width: 1300px) {
            margin-right: -44px;
        }

        @media (max-width: 1200px) {
            margin-right: -77px;
        }

        @media (max-width: 1150px) {
            margin-right: -93px;
        }

        @media (max-width: 1100px) {
            margin-right: -110px;
        }

        @media (max-width: 1023px) {
            margin-right: -49px;
            margin-top: 52px;
        }

        @media (max-width: 950px) {
            margin-right: -74px;
        }

        @media (max-width: 900px) {
            margin-right: -89px;
        }

        @media (max-width: 850px) {
            margin-right: -107px;
        }

        @media (max-width: 800px) {
            margin-right: -123px;
        }

        @media (max-width: 767px) {
            margin: 30px auto 20px;
            float: none;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        @media (max-width: 500px) {
            margin: 20px auto 30px;
        }

        .img-video {
            text-shadow: 5px 8px 0px black;

            .video-btn {
                display: inline;
                max-width: 78px;
                margin-right: 32px;
                cursor: pointer;
                transition: 1s;
                border-radius: 50%;

                @media (max-width: 1023px) {
                    max-width: 64px;
                    padding-right: 0px;
                    margin-right: 12px;
                }

                @media (max-width: 767px) {
                    width: 50%;
                }

                &:hover {
                    transform: scale(1.05);
                }
            }

            p {
                font-weight: 800;
                font-size: 24px;
                line-height: 29px;
                display: flex;
                align-items: center;
                color: $white;

                @media (max-width: 1023px) {
                    font-size: 18px;
                    line-height: 21px;
                    width: 220px;
                }
            }
        }
    }

    #parking-player {
        @media (max-width: 1023px) {
            padding: 10px;
        }

        @media (max-width: 767px) {
            padding: 150px 10px 0;

            div,
            iframe {
                max-width: 620px;
                width: auto;
                height: auto;
                bottom: 0;
                left: 0;
                position: absolute;
            }
        }
    }

    button {
        font-family: 'SFProDisplay', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $white;

        @media (max-width: 1233px) {
            margin-top: -175px;
        }

        @media (max-width: 1023px) {
            margin-top: 70px;
        }

        @media (max-width: 767px) {
            margin-top: -325px;
        }
    }
}