@import './_vars.scss';

.home_partner {
    position: relative;
    padding: 8rem 0 8.2154rem;
    overflow: visible;

    @media (max-width: 1537px) {
        padding: 3.6rem 0 7.8rem;
    }

    @media (max-width: 1023px) {
        padding: 0.7rem 0 0.8rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 1.3rem 0 0rem;
    }

    .cont.cont_sm {
        @media (max-width: 820px) {
            padding-left: 20px;
        }
    }

    .container {
        padding-left: 100px;

        @media (max-width: 1023px) {
            padding: 0;
        }
    }

    .btn-download {
        position: absolute;

        .btn.appstore {
            max-width: 206px;
            padding: 14px 30.18px 12.04px 30px;

            img {
                max-width: 100%;
                width: 145.82px !important;
            }

            @media (max-width: 767px) {
                max-width: 150px;
                padding: 14px 21px 12.63px 20px;

                img {
                    max-width: 100%;
                    width: 109px !important;
                }
            }
        }

        .btn.googleplay {
            max-width: 206px;
            padding: 15px 25.54px 12.98px 25px;

            img {
                max-width: 100%;
                width: 155.46px !important;
            }

            @media (max-width: 767px) {
                max-width: 150px;
                padding: 14.45px 15px 13.45px 18px;

                img {
                    max-width: 100%;
                    width: 117px !important;
                }
            }
        }
    }

    h2 {
        margin-top: -4px;

        line-height: 69px;
        letter-spacing: -0.01em;

        @media (max-width: 1439px) {
            margin-top: -5px;
        }

        @media (max-width: 1279px) {
            @include font-size(2.5, 1.188, 0);
        }

        @media (max-width: 1023px) {
            font-size: 30px;
            line-height: 36px;
            margin-top: 0px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 33px;
        }
    }

    .home_partner_subtitle {
        margin: 30px 0 68px;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;

        @media (max-width: 1639px) {
            margin: 26px 0 60px;
            font-size: 21px;
            line-height: 28px;
        }

        @media (max-width: 1023px) {
            margin: 18px 0 60px;
            font-size: 18px;
            line-height: 23px;
        }

        @media (max-width: 767px) {
            margin: 16px 0 24px;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .background-line {
        position: absolute;
        top: 36%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;
        opacity: 0.5;

        &.desktop-t {
            display: none;
        }

        &.desktop-m {
            display: none;
        }

        @media (max-width: 1023px) {
            &.desktop-d {
                display: none;
            }

            &.desktop-t {
                display: block;
                top: 215px
            }
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }
        }
    }

    .path {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    :not(svg) {
        transform-origin: 0px 0px;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }

    .contant {
        margin-top: 268px;
        background: $primary_black;
        position: relative;
        z-index: 3;

        &:before {
            position: absolute;
            content: ' ';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/home/partner/home_partner_rectangle.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            opacity: 0.2;
            z-index: 1;
        }

        @media (max-width: 16399px) {
            margin-top: 258px;
        }

        @media (max-width: 1279px) {
            margin-top: 282px;
        }

        @media (max-width: 1023px) {
            margin-top: 248px;
        }

        @media (max-width: 767px) {
            margin-top: 179px;
        }
    }

    .home_partner-grid {
        padding: 59px 70px 19px 70px;
        max-height: 429px;
        background-image: url("../img/home/partner/bg_parnter.png");
        background-repeat: no-repeat;
        background-size: contain;
        border: 1px solid #C4C4C40D;
        border-radius: 15px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 30px 30px;
        z-index: 2;

        @media (max-width: 1639px) {
            padding: 50px 60px 10px 60px;
        }

        @media (max-width: 1279px) {
            padding: 40px 50px 10px 50px;
        }

        @media (max-width: 1023px) {
            padding: 32px 32px 83px 32px;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            padding: 24px 24px 351px 24px;
            background-size: cover;
        }

        .block_info {
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 26px;

                @media (max-width: 1639px) {
                    font-size: 18px;
                    line-height: 21px;
                }

                @media (max-width: 1279px) {
                    font-size: 16px;
                    line-height: 18px;
                }

                @media (max-width: 1023px) {
                    font-size: 15px;
                    line-height: 130%;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 18px;
                }
            }

            li {
                display: inline-flex;
                margin-bottom: 40px;
                align-items: center;

                @media (max-width: 1639px) {
                    margin-bottom: 30px;
                }

                @media (max-width: 1279px) {
                    margin-bottom: 20px;
                }

                @media (max-width: 1023px) {
                    margin-bottom: 24px;
                }

                @media (max-width: 767px) {
                    min-width: 365px;
                }

                @media (max-width: 440px) {
                    min-width: 200px;
                    max-width: 300px;
                }
            }

            .li_img {
                width: 48px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.05);
                margin-right: 15px;

                @media (max-width: 1023px) {
                    width: 36px;
                }

            }

            .home_partner_img1 {
                max-width: 21px;
                padding: 12px 12.45px 12px 14.45px;

                @media (max-width: 1023px) {
                    max-width: 14.06px;
                    padding: 10px 10.3px 10px 11.63px;
                }
            }

            .home_partner_img2 {
                max-width: 22.5px;
                padding: 12px 12.45px 12px 14.45px;

                @media (max-width: 1023px) {
                    max-width: 16px;
                    padding: 10px 10px 10px 10px;
                }
            }

            .home_partner_img3 {
                max-width: 23.99px;
                padding: 12px 12px 12px 12px;

                @media (max-width: 1023px) {
                    max-width: 16px;
                    padding: 10px 10px 10px 10px;
                }
            }

            .home_partner_img4 {
                max-width: 24px;
                padding: 12px 12px 12px 12px;

                @media (max-width: 1023px) {
                    max-width: 16px;
                    padding: 10px 10px 10px 10px;
                }
            }
        }

        .block-img {
            align-self: flex-end;
            z-index: 4;

            @media (max-width: 767px) {
                align-self: center;
                margin: 0 auto;
            }

            img {
                position: absolute;
                top: -22%;
                max-width: 300px;

                @media (max-width: 1639px) {
                    top: -17.7%;
                    max-width: 250px;
                }

                @media (max-width: 1279px) {
                    top: 2%;
                    max-width: 180px;
                }

                @media (max-width: 1023px) {
                    top: -9.3%;
                    max-width: 223px;
                }

                @media (max-width: 767px) {
                    max-width: 203px;
                    top: 72.44%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }
            }
        }
    }
}