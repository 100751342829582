@import './_vars.scss';

.parking_faq {
    position: relative;
    padding: 9.24rem 0 8.3rem;

    @media (max-width: 1537px) {
        padding: 9.24rem 0 7.3rem;
    }

    @media (max-width: 1439px) {
        padding: 9.4rem 0 7.1rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 2rem;
    }

    @media (max-width: 1023px) {
        padding: 5.65rem 0 4.2rem;
    }

    @media (max-width: 767px) {
        padding: 3.85rem 0 3rem;
    }

    .background-line {
        position: absolute;
        top: 245px !important;
        width: 100%;
        left: 0 !important;
        right: 0;
        z-index: 0;

        @media (max-width: 1639px) {
            top: 200px;
        }

        @media (max-width: 1279px) {
            top: 90px;
        }

        @media (max-width: 1023px) {
            display: none;
        }

        .path {
            stroke-dasharray: 5;
            -webkit-animation: dash 40s linear;
            animation: dash 40s linear;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
        }

        :not(svg) {
            transform-origin: 0px 0px;
        }

        @keyframes dash {
            100% {
                stroke-dashoffset: 1000;
            }
        }
    }

    h2 {
        @media (max-width: 1439px) {
            padding: 0 0 76px 70px;
        }

        @media (max-width: 1023px) {
            padding: 5px 0 53px;
        }
    }

    .accordions {

        .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.accordions,
        #panel1-header,
        #panel2-header,
        #panel3-header,
        #panel4-header {
            padding: 40px 70px 37px 56px;

            @media (max-width: 1439px) {
                padding: 38px 117px 36px 68px;
            }

            @media (max-width: 1023px) {
                padding: 9px 19px 19px 29px;
            }

            p {
                padding-right: 100px;

                @media (max-width: 1350px) {
                    padding-right: 50px;
                }

                @media (max-width: 1200px) {
                    padding-right: 30px;
                }

                @media (max-width: 1023px) {
                    padding-right: 10px;
                }
            }
        }
    }

    .accordions .css-15v22id-MuiAccordionDetails-root {
        @media (max-width: 1023px) {
            padding: 0 30px 30px;
            margin-top: -26px;
        }
    }
}