@import './_vars.scss';

.home_bg-drops1 {
    background-image: url(../img/home/bgrnd_drops1.png);
    background-size: 520px 734px;
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: center;
}

.home_services {
    position: relative;
    padding: 0 0 6.7rem 0;
    overflow: hidden;

    @media (max-width: 1023px) {
        padding: 3rem 0 6.55rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 3.6rem 0 0;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0 0;
    }

    h2 {
        letter-spacing: -0.01em;
        max-width: 870px;
        margin-top: -7px;
        margin-left: 11px;
        line-height: 1.19em;

        @media (max-width: 1439px) {
            margin-top: -5px;
        }

        @media (max-width: 1023px) {
            margin: 0;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 33px;
        }
    }

    h3 {
        font-weight: 800;
        font-size: 36px;
        line-height: 43px;
    }

    .home_slider-styles {
        margin: 80px 0 0 475px;

        @media (max-width: 1839px) {
            margin: 80px 0 0 20%;
        }

        @media (max-width: 1639px) {
            margin-left: 18.4%;
        }

        @media (max-width: 1560px) {
            margin-left: 17.8%;
        }

        @media (max-width: 1520px) {
            margin-left: 17%;
        }

        @media (max-width: 1500px) {
            margin-left: 17.2%;
        }

        @media (max-width: 1480px) {
            margin-left: 17.4%;
        }

        @media (max-width: 1366px) {
            margin-left: 15.9%;
        }

        @media (max-width: 1279px) {
            margin-left: 16.9%;
        }

        @media (max-width: 1200px) {
            margin-left: 18%;
        }

        @media (max-width: 1150px) {
            margin-left: 19%;
        }

        @media (max-width: 1100px) {
            margin-left: 20%;
        }

        @media (max-width: 1060px) {
            margin-left: 20.6%;
        }

        @media (max-width: 1023px) {
            margin: 53px 0 0 70px;
        }

        @media (max-width: 767px) {
            margin: 165px 20px 0;
        }

        .slick-list {
            // background: none !important;
            // z-index: 0;
            overflow: inherit;

            @media (max-width: 767px) {
                height: 685px !important;
            }
        }

        .slick-dots {
            position: relative !important;
            top: -607px;
            z-index: 0;
            padding-left: 400px;
            text-align: left;
            scroll-snap-type: mandatory;
            white-space: nowrap;
            display: flex !important;
            flex-direction: row;
            // pointer-events: none;

            @media (max-width: 1840px) {
                top: -545px;
            }

            @media (max-width: 1439px) {
                top: -484px;
            }

            @media (max-width: 1221px) {
                top: -424px;
                padding-left: 280px;
            }

            @media (max-width: 1023px) {
                top: -463px;
                margin-left: 279px;
                padding: 0;
            }

            @media (max-width: 767px) {
                margin: 0;
                top: -800px;
                text-align: center;
                padding-left: 100px;
            }
        }

        .slick-dots li {
            width: 270px;
            height: 80px;
            display: inline-flex;

            @media (max-width: 1700px) {
                width: 230px;
                height: 65px;
            }

            @media (max-width: 1279px) {
                margin: 0 10px !important;
            }

            @media (max-width: 1023px) {
                margin: 0 15px 0 0 !important;
            }

            @media (max-width: 767px) {
                margin: 0 !important;
            }

            @media (max-width: 500px) {
                margin: 0 -30px 0 0 !important;
            }

            @media (max-width: 440px) {
                margin: 0 0 5px 0 !important;
            }
        }

        .custom_dots {
            float: left;
            width: 270px;
            // margin-right: 15px;
            display: inline-flex;
            column-gap: 10px;
            vertical-align: middle;
            text-align: center;
            align-items: center;
            background: #131518;
            border: 1px solid #2a3038;
            border-radius: 15px;
            padding: 16px 0px 16px 10px;
            transition: transform 0.3s ease;

            &.active_dot {
                transform: translateX(-100px);
            }

            @media (max-width: 1840px) {
                width: 255px;
            }

            @media (max-width: 1439px) {
                padding: 10px 0 10px 10px;
                margin-right: 10px;
            }

            @media (max-width: 1023px) {
                max-width: 220px;
                padding: 10px;
                column-gap: 10px;
            }

            @media (max-width: 400px) {
                width: 200px;
            }

            p {
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: $white;

                @media (max-width: 1639px) {
                    font-size: 15px;
                    line-height: 18px;
                }

                @media (max-width: 1023px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .home_slider_li-img {
                background: rgba(255, 255, 255, 0.05);
                border-radius: 50%;
                padding: 14px;

                @media (max-width: 1439px) {
                    padding: 11px;
                }
            }

            img {
                width: 20px;
                filter: grayscale(100%);

                @media (max-width: 1439px) {
                    width: 15px;
                }
            }
        }

        .slick-active .custom_dots {
            background: #191d22;
        }

        .slick-active .custom_dots .home_slider_li-img img {
            filter: grayscale(0%);
        }

        .fade-out {
            animation: fadeOut 0.5s forwards;
            transform: translateX(0);
        }

        @keyframes fadeOut {
            to {
                opacity: 0;
                visibility: hidden;
                transform: translateX(-200%);
            }
        }

        .grid {
            display: grid !important;
            grid-template-columns: 300px 100%;
            grid-gap: 30px 100px;
            justify-content: start;

            @media (max-width: 1439px) {
                grid-template-columns: 301px 100%;
            }

            @media (max-width: 1221px) {
                grid-gap: 30px 0;
            }

            @media (max-width: 1023px) {
                grid-template-columns: 230px 100%;
                grid-gap: 0 50px;
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .item {
                padding: 0;

                @media (max-width: 767px) {
                    // justify-self: start;
                    margin: 0 auto;
                }

                .item-content {
                    margin-top: 195px;

                    @media (max-width: 1439px) {
                        margin-top: 140px;
                    }

                    @media (max-width: 767px) {
                        margin-top: 22px;
                    }

                    h3 {
                        @media (max-width: 1023px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }

                    #item-hometext {
                        max-width: 430px;
                        @include font-size(1.125, 1.611, 0);

                        @media (max-width: 1439px) {
                            @include font-size(1.063, 1.611, 0);
                            max-width: 400px;
                        }

                        @media (max-width: 1023px) {
                            padding-top: 24px;
                            font-size: 16px;
                            line-height: 29px;
                        }

                        @media (max-width: 479px) {
                            max-width: 270px;
                            margin: 0 auto;
                        }
                    }
                }

                .item-btn {
                    display: inline-flex;
                    padding-top: 83px;
                    align-items: center;
                    position: absolute;
                    z-index: 100000;

                    @media (max-width: 1439px) {
                        padding-top: 63px;
                    }

                    @media (max-width: 1023px) {
                        padding-top: 48px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 21px;
                        margin: 0;
                        color: $yellow;
                        padding: 0 16px 0 0;
                    }

                    button {
                        background: rgba(255, 255, 255, 0.05);
                        vertical-align: middle;
                        border-radius: 4px;
                        border: none;
                        cursor: pointer;
                        padding: 0;

                        img {
                            max-width: 8px;
                            width: 100%;
                            padding: 6.01px 7.66px 5.99px 7.66px;
                        }
                    }
                }

                .img-wrap {
                    box-sizing: border-box;
                    width: 300px;
                    border-radius: 15px;

                    padding: 0;
                    margin: 0 auto;
                    grid-gap: 0;
                    float: left;
                    z-index: 20;

                    @media (max-width: 1023px) {
                        margin: 0;
                        width: 230px;
                    }

                    @media (max-width: 767px) {
                        margin: 0 auto;
                        justify-self: center;
                    }
                }
            }

            img {
                max-width: 436.44px;
                margin: 0;
                z-index: 100;

                @media (max-width: 1840px) {
                    max-width: 90%;
                }

                @media (max-width: 1439px) {
                    max-width: 80%;
                    margin: 0;
                }

                @media (max-width: 1220px) {
                    max-width: 70%;
                }

                @media (max-width: 1023px) {
                    max-width: 230px;
                }

                @media (max-width: 400px) {
                    margin: 0;
                }
            }

            h5 {
                margin: 1.5em auto 0.3em;

                @media (max-width: 767px) {
                    margin: 1em 0 0.7em;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.5em;
                color: $white;
                padding: 25px 0 0;

                @media (max-width: 1023px) {
                    padding: 30px 0 0;
                }
            }
        }
    }

    .slick-arrow {
        display: inline-flex;
        width: 80px;
        height: 80px;
        border-radius: 20px;
        position: absolute;
        z-index: 10000000;
        background: rgba(255, 255, 255, 0.05);
        top: 84%;

        @media (max-width: 1840px) {
            width: 60px;
            height: 60px;
        }

        img {
            width: 26px;

            @media (max-width: 1840px) {
                width: 20px !important;
            }
        }

        @media (max-width: 1439px) {
            width: 50px;
            height: 50px;
            border-radius: 15px;
        }

        @media (max-width: 1023px) {
            width: 60px;
            height: 60px;
            border-radius: 20px;
            top: 82%;
        }

        @media (max-width: 767px) {
            display: none;
            visibility: hidden;
        }
    }

    .slick-next {
        right: 25.8%;

        @media (max-width: 1023px) {
            left: 82%;
        }

        &:before {
            content: url(../img/home/services/next.png);
            width: 26px;
            line-height: 0;

            @media (max-width: 1840px) {
                content: url(../img/home/services/next_sm.png);
            }
        }

        &:hover:before {
            color: $white;
        }
    }

    .slick-prev {
        left: 61.5%;

        @media (max-width: 1840px) {
            left: 64.2%;
        }

        @media (max-width: 1700px) {
            left: 63.7%;
        }

        @media (max-width: 1639px) {
            left: 63.4%;
        }

        @media (max-width: 1560px) {
            left: 63%;
        }

        @media (max-width: 1500px) {
            left: 62.7%;
        }

        @media (max-width: 1439px) {
            left: 64%;
        }

        @media (max-width: 1366px) {
            left: 63.5%;
        }

        @media (max-width: 1279px) {
            left: 63%;
        }

        @media (max-width: 1200px) {
            left: 61.7%;
        }

        @media (max-width: 1150px) {
            left: 61%;
        }

        @media (max-width: 1100px) {
            left: 60.4%;
        }

        @media (max-width: 1060px) {
            left: 59.6%;
        }

        @media (max-width: 1023px) {
            left: 74%;
        }

        @media (max-width: 900px) {
            left: 73%;
        }

        @media (max-width: 850px) {
            left: 72%;
        }

        &:before {
            content: url(../img/home/services/prev.png);
            line-height: 0;

            @media (max-width: 1840px) {
                content: url(../img/home/services/prev_sm.png);
            }
        }

        &:hover:before {
            color: $white;
        }
    }
}
