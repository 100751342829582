@import './_vars.scss';

.parking_slider {
    padding: 5.15rem 0 7rem;

    @media (max-width: 1439px) {
        padding: 12.1rem 0 7rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 2.75rem 0 6.9rem;
    }

    @media (max-width: 767px) {
        padding: 2.9rem 0 0rem;
    }

    @media (max-width: 479px) {
        padding: 2.8rem 0;
    }

    .slider-section {
        margin-top: -1px;

        input[type=range] {
            @media (max-width: 1920px) {
                top: 35%;
                width: 277px;
                left: 10.5%;
            }

            @media (max-width: 1639px) {
                top: 34.5%;
                left: 7.2%;
            }

            @media (max-width: 1537px) {
                left: 7.6%;
            }

            @media (max-width: 1439px) {
                top: 33.5%;
                left: 7%;
            }

            @media (max-width: 1330px) {
                top: 37.9%;
                left: 7.4%;
            }

            @media (max-width: 1200px) {
                left: 8.3%;
            }

            @media (max-width: 1023px) {
                top: 16%;
                left: -5%;
            }

            @media (max-width: 850px) {
                left: -6%;
            }

            @media (max-width: 767px) {
                width: 200px;
                top: -4.8%;
                left: -8.1%;
            }

            @media (max-width: 720px) {
                top: -3.9%;
                left: -8.6%;
            }

            @media (max-width: 640px) {
                left: -9.6%;
            }

            @media (max-width: 600px) {
                top: -4%;
                left: -10.1%;
            }

            @media (max-width: 540px) {
                left: -11.3%;
            }

            @media (max-width: 480px) {
                top: -5%;
                left: -12.9%;
            }

            @media (max-width: 500px) {
                left: -12.3%;
            }

            @media (max-width: 400px) {
                top: -9%;
                left: -13%;
                width: 42%;
            }
        }

        .slick-vertical .slick-slide {
            @media (max-width: 767px) {
                height: 736px;
            }

            @media (max-width: 420px) {
                height: 710px;
            }
        }

        .grid {

            @media (max-width: 767px) {
                grid-gap: 0;
            }

            .item {
                &:nth-of-type(2) {
                    @media (max-width: 767px) {
                        justify-self: center;
                    }
                }

                h6 {
                    margin-top: 130px;

                    @media (max-width: 1439px) {
                        margin-top: 180px;
                    }

                    @media (max-width: 1023px) {
                        margin-top: 64px;
                    }
                }

                .item-header {
                    max-width: 590px;

                    @media (max-width: 1439px) {
                        max-width: 418px;
                    }
                }

                .item-content {
                    padding-top: 296px;

                    @media (max-width: 1639px) {
                        padding-left: 252px;
                    }

                    @media (max-width: 1439px) {
                        padding-left: 180px;
                    }

                    @media (max-width: 1023px) {
                        max-width: 277px;
                        padding-top: 145px;
                        padding-left: 62px;
                    }

                    @media (max-width: 767px) {
                        padding-top: 84px;
                        max-width: 308px;
                        padding-left: 65px;
                    }

                    @media (max-width: 480px) {
                        padding-top: 86px;
                    }

                    @media (max-width: 400px) {
                        padding-top: 49px;
                        padding-left: 35px;
                    }

                    @media (max-width: 375px) {
                        padding-left: 40px;
                        max-width: 250px;
                    }

                    h3 {
                        font-weight: 800;
                        font-size: 40px;
                        line-height: 48px;

                        @media (max-width: 1500px) {
                            font-size: 30px;
                            line-height: 36px;
                        }

                        @media (max-width: 1200px) {
                            font-size: 26px;
                            line-height: 30px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 24px;
                            line-height: 29px;
                        }

                        @media (max-width: 421px) {
                            font-size: 22px;
                            line-height: 27px;
                            margin-top: 26px;
                        }
                    }

                    #item-text {

                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;

                        @media (max-width: 1500px) {
                            font-size: 18px;
                            line-height: 24px;
                        }

                        @media (max-width: 1200px) {
                            @include font-size (1, 1.813, 0)
                        }

                        @media (max-width: 767px) {
                            line-height: 24px;
                            padding: 30px 0 0;
                            font-size: 14px;
                        }

                        @media (max-width: 395px) {
                            line-height: 20px;
                        }
                    }
                }

                .img-wrap {
                    max-height: 684px;
                    background-size: 495px 419px;

                    @media (max-width: 1439px) {
                        height: 623px;
                        width: 633px;
                    }

                    @media (max-width: 1330px) {
                        max-height: 540px;
                        max-width: 420px;
                    }

                    @media (max-width: 1023px) {
                        background-size: 335px 270px;
                        margin-top: 0;
                        max-height: 460px;
                        max-width: 335px;
                    }

                    @media (max-width: 767px) {
                        max-height: 460px;
                    }
                }

                img {
                    max-width: 398px;
                    padding-top: 58px;

                    @media (max-width: 1330px) {
                        max-width: 330px;
                    }

                    @media (max-width: 1023px) {
                        max-width: 278.62px;
                    }
                }
            }
        }

        .slick-dots {
            bottom: 0%;

            @media (max-width: 1695px) {
                bottom: 0%;
                margin: 0 64px 0;
            }

            @media (max-width: 1439px) {
                bottom: 4%;
                margin: 0 64px 0;
            }

            @media (max-width: 1330px) {
                bottom: 0%;
                margin: 0;
            }

            @media (max-width: 767px) {
                bottom: 57%;
            }

            li {
                @media (max-width: 1439px) {
                    margin-top: 55px;
                }

                @media (max-width: 767px) {
                    margin-top: 25px;
                }
            }
        }

        .slick-list {
            @media (max-width: 1023px) {
                height: 460px !important;
            }

            @media (max-width: 767px) {
                height: 754px !important;
            }
        }

        .slick-track {
            background: #ffffff00 !important;
        }
    }
}