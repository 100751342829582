@import './_vars.scss';

.insurance_protection {
    position: relative;
    padding: 13.6rem 0 7rem;

    @media (max-width: 1537px) {
        padding: 15.7rem 0 6rem;
    }

    @media (max-width: 1439px) {
        padding: 15rem 0 6rem;
    }

    @media (max-width: 1023px) {
        padding: 7.6rem 0;
    }

    @media (max-width: 767px) {
        padding: 13.3rem 0 7.35rem;
    }

    @media (max-width: 479px) {
        padding: 7.5rem 0;
    }

    h2 {
        margin-top: -2px;
        padding-left: 89px;
        line-height: 1em;

        @media (max-width: 1439px) {
            margin-top: 2px;
            padding-left: 70px;
            line-height: 1.19em;
        }

        @media (max-width: 1023px) {
            padding: 5px 0 0;
        }
    }

    .grid {
        max-width: 1388px;
        margin: 105px auto 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 45px 30px;
        justify-content: center;
        justify-self: center;
        padding-left: 89px;

        @media (max-width: 1537px) {
            margin-top: 119px;
        }

        @media (max-width: 1439px) {
            margin-top: 9em;
            padding-left: 70px;
            grid-gap: 30px 30px;
        }

        @media (max-width: 1023px) {
            margin-top: 2.6em;
            padding: 0;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 75px 30px;
        }

        .item {
            padding: 0;

            .img-wrap {
                box-sizing: border-box;
                max-width: 355px;
                border-radius: 15px;
                padding: 0;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: center;
                align-items: center;
                grid-gap: 0;

                @media (max-width: 1439px) {
                    max-width: 300px;
                }

                @media (max-width: 1280px) {
                    max-width: 100%;
                }

                @media (max-width: 600px) {
                    max-width: 100%;
                }
            }

            .list-item {
                max-width: 355px;

                @media (max-width: 1023px) {
                    max-width: 210px;
                }

                @media (max-width: 767px) {
                    max-width: 100%;
                }
            }
        }

        img {
            max-width: 100%;
            width: auto;
            margin: 0 auto;
            animation-name: slideUp;
            -webkit-animation-name: slideUp;

            animation-duration: 2s;
            -webkit-animation-duration: 2s;

            animation-timing-function: linear;
            -webkit-animation-timing-function: linear;
        }

        h5 {
            margin: 50px auto 0;

            @media (max-width: 1439px) {
                margin: 1.7em auto 0;
            }

            @media (max-width: 1023px) {
                margin: 1.55em auto 0;
            }

            @media (max-width: 767px) {
                margin: 1em 0 0;
            }
        }

        p {
            padding: 7px 0;

            @media (max-width: 1439px) {
                padding: 11px 0 0;
            }

            @media (max-width: 1023px) {
                padding: 14px 0 0;
            }
        }
    }
}
