@import './_vars.scss';

/*  Tags  */
.testing {
    margin-top: 100px;
}

html {
    font-size: 16px;
}

/*Fonts*/
@font-face {
    font-family: 'SFProDisplay';
    src: url('../font/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('../font/SFProDisplay-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('../font/SFProDisplay-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

body {
    width: 100%;
    background-color: $primary_black;
    background-image: url(../img/bg2.png);
}

section {
    padding: 4.9rem 0 4.2rem;

    @media (max-width: 1279px) {
        padding: 7rem 0;
    }

    @media (max-width: 600px) {
        padding: 5rem 0;
    }
}

.article {
    background-color: #f8f8f8;
}

section,
footer {
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

img {
    width: 100%;
    display: block;
    image-rendering: -webkit-optimize-contrast;
    // -webkit-user-select: none;
    // -khtml-user-select: none;
    // -moz-user-select: none;
    // -o-user-select: none;
    // user-select: none;
}

/* Typography */
h1,
h2,
h3,
h4,
h5 {
    color: $white;
    font-family: 'SFProDisplay', sans-serif;
    font-weight: 800;
    margin: 0;

    &::selection {
        color: $white;
        background-color: $black;
    }
}

h1 {
    @include font-size(4.625, 1.189, 0);
    font-style: normal;
    font-weight: 800;
    color: $white;
    position: relative;
    margin: 0 auto;
    padding-bottom: 0.2em;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    span {
        font-weight: 700;
    }

    @media (max-width: 1639px) {
        font-size: 64px;
        line-height: 76px;
    }

    @media (max-width: 1023px) {
        @include font-size(2.25, 1.194, 0);
        padding-bottom: 0;
    }

    @media (max-width: 767px) {
        font-size: 1.875rem;
    }
}

h2 {
    @include font-size(3.625, 1.19, 0);
    color: $white;
    font-weight: 800;

    @media (max-width: 1639px) {
        @include font-size(3, 1.188, 0);
    }

    @media (max-width: 1023px) {
        font-size: 1.875rem;
    }
}

h3 {
    @include font-size(2.5, 1.2, 0);
    color: $white;
    font-weight: 800;

    @media (max-width: 1639px) {
        @include font-size(2.25, 1.194, 0);
    }

    @media (max-width: 1023px) {
        font-size: 1.5rem;
    }
}

h4 {
    @include font-size(2.125, 1.206, 0);
    color: $white;

    @media (max-width: 1639px) {
        @include font-size(2, 1.188, 0);
    }

    @media (max-width: 1023px) {
        font-size: 1rem;
    }
}

h5 {
    @include font-size(1.75, 1.179, 0);
    margin: 0;

    @media (max-width: 1639px) {
        @include font-size(1.5, 1.188, 0);
    }

    @media (max-width: 1023px) {
        font-size: 1rem;
    }

    @media (max-width: 767px) {
        font-size: 1.5rem;
    }
}

h6 {
    font-family: 'SFProDisplay', sans-serif;
    @include font-size(1.25, 1.2, 0);
    font-style: normal;
    color: $yellow;
    text-transform: uppercase;
    margin: 0;
    font-weight: 400;

    @media (max-width: 1639px) {
        @include font-size(1, 1.188, 0);
    }

    @media (max-width: 1023px) {
        font-size: 0.75rem;
    }

    & + h2 {
        margin-top: -2px;
        padding-left: 89px;
        line-height: 1em;

        @media (max-width: 1639px) {
            padding-left: 70px;
            line-height: 1.19em;
        }

        @media (max-width: 1023px) {
            padding: 3px 0 0;
        }
    }
}

p {
    font-family: 'SFProDisplay', sans-serif;
    @include font-size(1.313, 1.381, 0);
    font-weight: 400;
    color: $white;
    padding: 0;
    margin: 0;

    @media (max-width: 1639px) {
        @include font-size(1.125, 1.611, 0);
    }

    @media (max-width: 1023px) {
        @include font-size(0.875, 1.5, 0);
    }

    &::selection {
        color: $white;
        background-color: $black;
    }
}

a {
    font-family: 'SFProDisplay', sans-serif;
    @include font-size(1.25, 1.2);
    font-weight: 400;
    text-decoration: none;
    text-transform: capitalize;
    cursor: pointer;
    display: inline-block;
    transition: all 0.2s ease-in;
    color: $white;

    @media (max-width: 1639px) {
        @include font-size(0.875, 1.188);
    }

    &::selection {
        color: $white;
        background-color: $black;
    }
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li {
    list-style-type: none;
    font-weight: 400;
    font-family: 'SFProDisplay', sans-serif;
    font-size: 1.125rem;
    line-height: 1.67em;
    letter-spacing: -0.02em;
    color: #fff;
    padding: 0;
    margin: 0;
}

/*  Containers  */
.background {
    background-size: cover;
}

.content-wrap {
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.cont_main {
    padding: 0 119px;
    margin: 0 auto;

    @media (max-width: 1639px) {
        padding: 0 70px;
    }

    @media (max-width: 767px) {
        padding: 0 $pad_md;
    }
}

.cont {
    padding: 0 220px;
    margin: 0 auto;

    @media (max-width: 1639px) {
        padding: 0 135px;
    }

    @media (max-width: 1023px) {
        padding: 0 70px;
    }

    @media (max-width: 767px) {
        padding: 0 $pad_md;
    }
}

.cont_mx {
    max-width: 100%;
    padding: 0;
}

.cont_lg {
    max-width: 1800px;
}

.cont_ft {
    max-width: 1350px;
    position: relative;
    z-index: 2;

    @media (max-width: 1023px) {
        max-width: 885px;
    }
}

.cont_md {
    max-width: 1700px;

    @media (max-width: 767px) {
        max-width: 600px;
    }
}

.cont_sm {
    max-width: 1170px;

    @media (max-width: 767px) {
        max-width: 600px;
    }
}

.cont_hr {
    max-width: 1470px;
}

.row {
    @include flex-between;
}

.container-baground {
    background-image: url('../img/insurance/ins_bg_join-faq.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 40%;

    @media (max-width: 767px) {
        background-position: 0 32%;
        background-size: 62% 33%;
    }
}

/*  General Classes */

/*Buttons*/
.btn {
    font-family: 'SFProDisplay', sans-serif;
    @include font-size(1.25, 1.2);
    font-weight: 700;
    color: $primary_black;
    background: $yellow;
    // background: #F7B500;
    padding: 1.5em 4.625em;
    text-transform: none;
    cursor: pointer;
    border: 2px solid $black;
    border-radius: 15px;
    box-shadow: 8px 7px 0px rgba(0, 0, 0, 1);

    @media (max-width: 1639px) {
        @include font-size(1, 1.188);
        padding: 1.25em 1.25em;
    }

    @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 17px;
        padding: 1em 1em;
    }

    @media (max-width: 767px) {
        padding: 0.87em 0.87em;
    }

    &:hover {
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0);
        cursor: pointer;
    }

    &:active {
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0);
        border: 4px solid $black;
    }

    &.alt_btn {
        color: $primary_black;
        background: $yellow;

        &:hover {
            background-color: $white;
            color: $primary_black;
            cursor: pointer;
        }
    }
}

.btn-download {
    display: grid;
    grid-template-columns: repeat(2, 206px);
    grid-gap: 25px 25px;
    justify-content: start;
    position: relative;
    z-index: 5;

    @media (max-width: 1023px) {
        grid-template-columns: repeat(2, 150px);
    }

    .btn {
        padding: 14px 30px 12px;

        @media (max-width: 1639px) {
            max-width: 90%;
            padding: 1em 1.2em;
        }

        img {
            max-width: 145px;

            @media (max-width: 1639px) {
                max-width: 90%;
            }

            @media (max-width: 1023px) {
                max-width: 109px;
            }
        }

        @media (max-width: 1023px) {
            padding: 14px 21px 13px 20px;
        }
    }

    .googleplay {
        background: $white;
    }
}

.sub {
    margin: 0 auto;
    color: $grey;
    max-width: 500px;

    @media (max-width: 1279px) {
        line-height: 1.4em;
    }
}

/* Text */

.sup {
    font-family: 'SFProDisplay', sans-serif;
    text-transform: capitalize;
    color: $yellow;
    font-size: 1.063rem;
    line-height: 1.471em;
    letter-spacing: 0em;
    font-weight: 400;
}

.text {
    font-family: 'SFProDisplay';
    font-style: normal;
    @include font-size(1.313, 1.381);
    font-weight: 400;
    color: $white;

    @media (max-width: 1439px) {
        font-size: 1.125rem;
        line-height: 1.611em;
        letter-spacing: 0em;
    }

    @media (max-width: 1023px) {
        font-size: 0.875rem;
    }

    @media (max-width: 767px) {
        font-size: 1.125rem;
    }
}

.center {
    text-align: center;
}

.bg-round {
    z-index: -1 !important;
}

/*  Loader  */

#loader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $yellow;
    z-index: 1000;
    -webkit-transition: 0.3s ease opacity;
    transition: 0.3s ease opacity;
}

#loader:before,
#loader:after {
    content: '';
    width: 80px;
    height: 80px;
    border: 2px solid $yellow;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: loaderCircleAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

#loader:after {
    animation-delay: 1s;
}

@keyframes loaderCircleAnim {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }

    10% {
        width: 10px;
        height: 10px;
        opacity: 1;
    }

    80% {
        width: 60px;
        height: 60px;
        opacity: 0.1;
    }

    100% {
        width: 70px;
        height: 70px;
        opacity: 0;
    }
}

.container-vector {
    .background-line {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;

        &.desktop-t {
            display: none;
        }

        &.desktop-m {
            display: none;
        }

        @media (max-width: 1023px) {
            &.desktop-t {
                display: block;
                top: 39%;
            }
        }

        @media (max-width: 920px) {
            &.desktop-t {
                top: 37%;
            }
        }

        @media (max-width: 850px) {
            &.desktop-t {
                top: 36%;
            }
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }

            &.desktop-m {
                display: block;
                top: 47%;
            }
        }

        @media (max-width: 550px) {
            &.desktop-m {
                top: 46%;
            }
        }

        @media (max-width: 440px) {
            &.desktop-m {
                top: 45%;
            }
        }
    }

    .path_insurance {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }
}

.bp_container-vector {
    .background-line {
        position: absolute;
        top: 20%;
        left: 81%;
        right: 0;
        z-index: 0;

        &.desktop-t {
            display: none;
        }

        &.desktop-m {
            display: none;
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }

            &.desktop-m {
                display: block;
                top: 450px;
            }
        }
    }

    .path {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }
}

/* 404 */

.page-404 {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Social Media */

.socialmedia {
    display: inline-flex;

    .icons {
        background: rgba(255, 255, 255, 0.05);
        margin-top: 6px;
        padding: 15px;
        border-radius: 50%;
        margin-left: 22px;

        @media (max-width: 1439px) {
            padding: 11px;
            margin-top: -1px;
            margin-left: 15px;
        }

        img {
            max-width: 20.23px;

            @media (max-width: 1439px) {
                max-width: 16px;
            }
        }

        .telegram {
            padding-top: 2px;
        }
    }
}

.mail {
    text-align: end;

    .bottom-email {
        margin-top: 9px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-transform: none;

        @media (max-width: 1439px) {
            font-size: 0.875rem;
            margin-top: 16px;
        }
    }
}

/* Slider */

.slider-section {
    position: relative;
    padding: 0 220px;
    max-width: 1700px;

    @media (max-width: 1639px) {
        padding: 0 135px;
    }

    @media (max-width: 1300px) {
        padding: 0 100px;
    }

    @media (max-width: 1023px) {
        padding: 0 70px;
    }

    @media (max-width: 767px) {
        padding: 0 20px;
    }

    input[type='range'] {
        position: absolute;
        top: 38%;
        left: 0;
        width: 268px;
        -webkit-appearance: none;
        margin: 218px 0;
        transform: rotate(90deg);

        @media (max-width: 1439px) {
            top: 45%;
        }

        @media (max-width: 1023px) {
            top: 20%;
            width: 175px;
        }

        @media (max-width: 767px) {
            top: 0;
            left: -8%;
        }

        @media (max-width: 400px) {
            top: 5%;
            left: -10%;
        }
    }

    input[type='range']:focus {
        outline: none;
    }

    input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        cursor: pointer;
        background: #3e4243;
        z-index: 1;
    }

    input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 6px;
        width: 6px;
        padding: 6px;
        border-radius: 50%;
        color: $white;
        border: 24px solid #1f2024;
        background: $white;
        cursor: pointer;
        margin-top: -30px;
        z-index: 2;
        content: '•';

        @media (max-width: 1023px) {
            height: 3px;
            width: 3px;
            padding: 3px;
            border: 16px solid #1f2024;
            margin-top: -18.5px;
        }
    }

    .slider::-moz-range-thumb {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 24px solid #1f2024;
        background: $white;
        cursor: pointer;
        margin-top: -30px;
        z-index: 2;

        @media (max-width: 1023px) {
            height: 3px;
            width: 3px;
            padding: 3px;
            border: 16px solid #1f2024;
            margin-top: -18.5px;
        }
    }

    /* end range*/

    .slider-section_header {
        background-color: $primary_black;
        padding-bottom: 20px;
        width: 700px;
        background-image: url(../img/parking/partners/parking_partners_rectangle.png);
        position: absolute;
        z-index: 2;

        @media (max-width: 1500px) {
            width: 600px;
        }

        @media (max-width: 1439px) {
            width: 525px;
        }

        @media (max-width: 1150px) {
            width: 500px;
        }

        @media (max-width: 1100px) {
            width: 430px;
            padding-bottom: 80px;
        }

        @media (max-width: 1023px) {
            width: 380px;
            padding-bottom: 5px;
        }

        @media (max-width: 900px) {
            width: 345px;
        }

        @media (max-width: 800px) {
            width: 300px;
        }

        @media (max-width: 767px) {
            width: 380px;
            padding-bottom: 0px;
        }

        h6 {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-top: 91px;

            @media (max-width: 1639px) {
                @include font-size(1, 1.188, 0);
            }

            @media (max-width: 1023px) {
                margin-top: 50px;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
            }

            @media (max-width: 767px) {
                margin-top: 0;
            }
        }

        .item-header {
            padding-left: 89px;
            font-weight: 800;
            font-size: 58px;
            line-height: 69px;

            @media (max-width: 1639px) {
                font-size: 3rem;
            }

            @media (max-width: 1439px) {
                max-width: 500px;
                margin-left: 0;
                padding-left: 70px;
            }

            @media (max-width: 1300px) {
                padding-left: 105px;
            }

            @media (max-width: 1100px) {
                font-size: 36px;
                line-height: 40px;
            }

            @media (max-width: 1023px) {
                font-weight: 800;
                font-size: 30px;
                line-height: 36px;
                padding: 4px 0 0;
            }

            @media (max-width: 767px) {
                max-width: 415px;
                margin-left: 0;
            }
        }
    }

    /* Custom slider*/
    .slider_custom {
        position: relative;
        width: 80%;
        height: 500px;
        margin: auto;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .slider-img {
        position: absolute;
        width: 100%;
        height: 500px;
        transition: all 0.2s;
        font-size: 50px;
        color: $white;
    }

    .slider-img-prev {
        transform: translateX(-100%);
    }

    .slider-img-next {
        transform: translateX(100%);
    }

    /* end custom slider*/

    /* MUI Range */
    .MuiBox-root.css-16r9pk3 {
        max-width: 1500px;
    }

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: rgba(255, 255, 255, 0.2);
        border: 1px solid $primary_black;
    }

    .MuiStepIcon-text .css-117w1su-MuiStepIcon-text {
        display: none;
    }

    svg {
        color: rgba(255, 255, 255, 0.2);

        text {
            display: none;
        }
    }

    /* end MUI range*/
    .slider {
        padding-right: 50px;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 45px 30px;
        justify-content: space-between;

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            padding: 0;

            @media (max-width: 767px) {
                justify-self: start;
            }

            .item-content {
                padding-top: 380px;
                padding-left: 169px;
                max-width: 455px;

                @media (max-width: 1439px) {
                    padding-top: 380px;
                    padding-left: 140px;
                    max-width: 360px;
                }

                @media (max-width: 1300px) {
                    padding-left: 185px;
                }

                @media (max-width: 1023px) {
                    padding-top: 184px;
                    padding-left: 55px;
                }

                @media (max-width: 767px) {
                    padding-top: 171px;
                    padding-left: 65px;
                    max-width: 277px;
                }

                @media (max-width: 400px) {
                    padding-left: 55px;
                    max-width: 220px;
                }

                h3 {
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 48px;

                    @media (max-width: 1639px) {
                        @include font-size(2.25, 1.194, 0);
                    }

                    @media (max-width: 1023px) {
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 29px;
                    }
                }

                #item-text {
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 29px;

                    @media (max-width: 1439px) {
                        @include font-size(1.313, 1.381, 0);
                    }

                    @media (max-width: 1023px) {
                        @include font-size(1, 1.813, 0);
                    }
                }
            }

            .img-wrap {
                position: relative;
                box-sizing: border-box;
                width: 545px;
                height: 646px;
                border-radius: 15px;
                background-image: url(../img/insurance/slider/InsSliderBg1.png);
                background-repeat: no-repeat;
                background-position: bottom;
                background-position: 60% 30%;
                background-size: 545px 463px;
                background-position-y: bottom;
                padding: 0;
                margin: 0 auto;
                grid-gap: 0;
                float: right;
                z-index: 0;

                @media (max-width: 1439px) {
                    margin-top: 47px;
                    width: 495px;
                    height: 605px;
                    background-size: 495px 409px;
                }

                @media (max-width: 1220px) {
                    width: 400px;
                    height: 590px;
                    background-size: 420px 380px;
                }

                @media (max-width: 1023px) {
                    margin: 0;
                    max-height: 400px;
                    background-size: 300px 250px;
                    margin-top: 20%;
                    width: 90%;
                }

                @media (max-width: 767px) {
                    margin-top: 50px;
                }
            }

            img {
                max-width: 436.44px;
                margin: 0 auto;
                z-index: 1;

                @media (max-width: 1439px) {
                    max-width: 398px;
                }

                @media (max-width: 1220px) {
                    max-width: 380px;
                }

                @media (max-width: 1023px) {
                    max-width: 270px;
                }

                @media (max-width: 400px) {
                    margin: 0 auto;
                }
            }

            h5 {
                margin: 1.5em auto 0.3em;

                @media (max-width: 767px) {
                    margin: 1em 0 0.7em;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.5em;
                color: $white;
                padding: 25px 0 0;

                @media (max-width: 1023px) {
                    padding: 30px 0 0;
                }
            }
        }
    }

    .slick-dots {
        bottom: 3%;
        margin: 0 83px 0;

        @media (max-width: 1220px) {
            margin-left: 55px;
        }

        @media (max-width: 1023px) {
            margin-left: 0;
            bottom: 18%;
        }

        @media (max-width: 880px) {
            bottom: 15%;
        }

        @media (max-width: 767px) {
            margin: 0 5px;
            bottom: 55%;
        }
    }

    .slick-dots li {
        width: 38px;
        height: 38px;
        display: block;
        margin-top: 90px;

        @media (max-width: 1023px) {
            margin-top: 50px;
        }
    }

    .slick-dots li button {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 50%;
        width: 48px;
        height: 48px;

        @media (max-width: 1439px) {
            width: 38px;
            height: 38px;
        }
    }

    .slick-dots li button:before {
        width: 48px;
        height: 48px;
        opacity: 0.3;
        color: $white;
        padding-top: 14px;

        @media (max-width: 1439px) {
            width: 38px;
            height: 38px;
            padding-top: 9px;
        }
    }

    .slick-dots li.slick-active button:before {
        width: 48px;
        height: 48px;
        opacity: 0.75;
        color: $white;

        @media (max-width: 1439px) {
            width: 38px;
            height: 38px;
        }
    }

    .slider-bottom {
        margin-top: 100px;
    }
}

/* Slider-Home */
.home_slider {
    padding: 8.6rem 0 7rem;

    @media (max-width: 1439px) {
        padding: 9.8rem 0 7rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 2.3rem 0 5rem;
    }

    @media (max-width: 767px) {
        padding: 1rem 0 4rem;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0;
    }

    .home_slider-styles {
        margin: 80px 0 0 475px;

        .slick-slider {
            .slick-list {
                background-color: none !important;
                -webkit-tap-highlight-color: none;
            }
        }

        .slick-slider &.slick-initialized .slick-dots {
            position: relative !important;
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 70px 0px;
            justify-content: start;

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .item {
                padding: 0;

                @media (max-width: 767px) {
                    justify-self: center;
                }

                .item-content {
                    padding-top: 115px;

                    @media (max-width: 1439px) {
                        padding-top: 109px;
                        padding-left: 140px;
                        max-width: 360px;
                    }

                    @media (max-width: 1220px) {
                        padding-top: 90px;
                        padding-left: 110px;
                        max-width: 360px;
                    }

                    @media (max-width: 1023px) {
                        padding-top: 48px;
                        padding-left: 55px;
                    }

                    @media (max-width: 767px) {
                        padding-left: 65px;
                        max-width: 277px;
                    }

                    @media (max-width: 400px) {
                        padding-left: 55px;
                        max-width: 220px;
                    }
                }

                .img-wrap {
                    box-sizing: border-box;
                    width: 300px;
                    border-radius: 15px;

                    padding: 0;
                    margin: 0 auto;
                    grid-gap: 0;
                    float: left;

                    @media (max-width: 1439px) {
                        margin-top: 47px;
                        width: 495px;
                        height: 605px;
                        background-size: 495px 409px;
                    }

                    @media (max-width: 1220px) {
                        width: 400px;
                        height: 590px;
                        background-size: 420px 380px;
                    }

                    @media (max-width: 1023px) {
                        margin: 0;
                        max-height: 400px;
                        background-size: 300px 250px;
                        margin-top: 20%;
                        width: 90%;
                    }

                    @media (max-width: 767px) {
                        margin-top: 50px;
                    }
                }
            }

            img {
                max-width: 436.44px;
                margin: 0;

                @media (max-width: 1439px) {
                    max-width: 398px;
                }

                @media (max-width: 1220px) {
                    max-width: 380px;
                }

                @media (max-width: 1023px) {
                    max-width: 270px;
                }

                @media (max-width: 400px) {
                    margin: 0;
                }
            }

            h5 {
                margin: 1.5em auto 0.3em;

                @media (max-width: 767px) {
                    margin: 1em 0 0.7em;
                }
            }

            p {
                font-size: 1rem;
                line-height: 1.5em;
                color: $white;
                padding: 25px 0 0;

                @media (max-width: 1023px) {
                    padding: 30px 0 0;
                }
            }
        }

        .slick-list {
            background: none !important;
            -webkit-tap-highlight-color: none;
            overflow: inherit;
        }

        .slick-dots {
            top: 0;
            position: relative !important;
        }

        .slick-dots li {
            margin-right: 50px;

            @media (max-width: 1023px) {
                margin-top: 35px;
            }
        }

        .slick-dots li button {
            background: #191d22;
            border: 1px solid #2a3038;
            border-radius: 15px;
            width: 270px;
            height: 80px;

            @media (max-width: 1439px) {
                width: 38px;
                height: 38px;
            }
        }

        .slick-dots li button:before {
            width: 48px;
            height: 48px;
            opacity: 0.3;
            color: $white;
            padding-top: 14px;

            @media (max-width: 1439px) {
                width: 38px;
                height: 38px;
                padding-top: 9px;
            }
        }

        .slick-dots li.slick-active button:before {
            width: 48px;
            height: 48px;
            opacity: 0.75;
            color: $white;

            @media (max-width: 1439px) {
                width: 38px;
                height: 38px;
            }
        }

        .slider-bottom {
            margin-top: 100px;
        }
    }

    .slick-arrow {
        // display: inline-flex;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.05);
        top: 110%;

        img {
            width: 26px;
        }

        @media (max-width: 1439px) {
            width: 20px;
            height: 20px;
            top: 100%;
        }

        @media (max-width: 1023px) {
            top: 105%;
        }

        @media (max-width: 767px) {
            top: 110%;
        }
    }

    .slick-next {
        right: 20%;
    }

    .slick-prev {
        left: 65%;
    }
}

/* Slider-Home_Team */
.home_slider_team-styles {
    // white-space: nowrap;
    // border: 1px solid salmon;
    // width: 1300px;
    .slick-slider {
        // overflow: hidden;
    }

    .slick-slide {
        width: 275px !important;
        // border: 1px solid rgb(182, 250, 114);

        @media (max-width: 767px) {
            width: 168px !important;
        }

        @media (max-width: 440px) {
            // width: 140px !important;
        }
    }

    .slick-arrow {
        top: 380px;
        display: inline-flex;
        width: 60px;
        height: 60px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.05);
        z-index: 1;

        img {
            width: 26px;
        }

        @media (max-width: 1439px) {
            width: 60px;
            height: 60px;
            top: 340px;
        }

        @media (max-width: 1023px) {
            top: 325px;
        }

        @media (max-width: 767px) {
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }

        @media (max-width: 440px) {
            top: 240px;
        }
    }

    .slick-next {
        left: 76.5%;

        &:before {
            content: url(../img/home/services/next.png);
            width: 26px;
            line-height: 0;

            @media (max-width: 1840px) {
                content: url(../img/home/services/next_sm.png);
            }
        }

        &:hover:before {
            color: $white;
        }
    }

    .slick-prev {
        z-index: 1;
        left: 72.5%;

        @media (max-width: 1537px) {
            left: 71%;
        }

        @media (max-width: 1200px) {
            left: 70%;
        }

        @media (max-width: 1023px) {
            left: 69%;
        }

        @media (max-width: 850px) {
            left: 67%;
        }

        @media (max-width: 720px) {
            left: 65%;
        }

        @media (max-width: 541px) {
            left: 63%;
        }

        @media (max-width: 481px) {
            left: 61%;
        }

        @media (max-width: 441px) {
            left: 59%;
        }

        @media (max-width: 400px) {
            left: 57%;
        }

        &:before {
            line-height: 0;
            content: url(../img/home/services/prev.png);

            @media (max-width: 1840px) {
                content: url(../img/home/services/prev_sm.png);
            }
        }

        &:hover:before {
            color: $white;
        }
    }

    .slick-dots {
        width: 18%;
        bottom: 0%;
        left: 38%;

        @media (max-width: 1439px) {
            width: 20%;
        }

        @media (max-width: 1023px) {
            width: 20%;
        }

        @media (max-width: 830px) {
            width: 35%;
        }

        li.slick-active {
            border: 1px solid $white;
            border-radius: 50%;
            color: $white;
        }
    }

    .slick-dots li {
        width: 24px;
        height: 24px;
        margin: 0 4px;

        @media (max-width: 1439px) {
            width: 20px;
            height: 20px;
        }
    }

    .slick-dots li button:before {
        font-size: 17px;
        color: $white;
        padding-top: 3px;
        padding-left: 2px;

        @media (max-width: 1439px) {
            padding-top: 0px;
            padding-left: 0px;
            font-size: 10px;
        }
    }

    .slick-dots li.slick-active button:before {
        font-size: 8px;
        opacity: 0.75;
        color: $white;

        @media (max-width: 1439px) {
            font-size: 6px;
        }
    }

    .home_slider_team-grid {
        // display: flex;
        // grid-template-rows: repeat(2);
        // grid-gap: 0px 20px;
        // justify-content: start;
        // border: 1px solid red;

        @media (max-width: 767px) {
            // grid-gap: 10px 10px;
        }

        .item {
            // padding: 0;
            // border: 1px solid red;

            @media (max-width: 767px) {
                // justify-self: start;
            }

            .item-content {
                padding-right: 25px;
                // @media (max-width: 1639px) {
                //     // max-width: 230px;
                // }

                // @media (max-width: 1439px) {
                //     // max-width: 200px;
                // }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: none;
                    color: $white;

                    @media (max-width: 1439px) {
                        @include font-size(1.188, 1.313, 0);
                    }

                    @media (max-width: 1023px) {
                        @include font-size(1.125, 1.188, 0);
                    }

                    @media (max-width: 767px) {
                        font-size: 15.3462px;
                        line-height: 18px;
                        margin: 0;
                    }
                }

                // #item-home-position {
                //     padding-top: 2px;
                //     padding-bottom: 80px;
                //     font-style: normal;
                //     font-weight: 500;
                //     font-size: 16px;
                //     line-height: 22px;
                //     letter-spacing: 0.07em;
                //     color: #8e8e93;

                //     @media (max-width: 1439px) {
                //         @include font-size(0.938, 1.313, 0);
                //         padding-bottom: 50px;
                //     }

                //     @media (max-width: 1023px) {
                //         padding-bottom: 173px;
                //         font-size: 12.2769px;
                //         line-height: 17px;
                //     }

                //     @media (max-width: 767px) {
                //         padding-bottom: 183px;
                //     }

                //     @media (max-width: 481px) {
                //         padding-bottom: 175px;
                //     }
                // }
            }

            .img-wrap {
                // box-sizing: border-box;
                // width: 184px;
                // border-radius: 15px;

                // padding: 0;
                // margin: 0 auto;
                // grid-gap: 0;
                // float: left;

                @media (max-width: 1439px) {
                    width: 160px;
                }

                @media (max-width: 1023px) {
                    width: 184px;
                }

                @media (max-width: 767px) {
                    width: 141px;
                }

                @media (max-width: 440px) {
                    width: 90px;
                }
            }

            &:hover img {
                filter: contrast(30%) brightness(150%) opacity(0.2);
            }

            a:hover {
                background-image: url(../img/home/team/linkedin.png);
                background-repeat: no-repeat;
                background-size: 50px;
                background-position: center;
                filter: saturate(120%) contrast(95%) opacity(1);
                z-index: 100;
            }

            a {
                transition: none;
            }

            img {
                max-width: 184px;
                margin: 0 auto;
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;

                @media (max-width: 1439px) {
                    max-width: 398px;
                }

                @media (max-width: 1220px) {
                    max-width: 380px;
                }

                @media (max-width: 1023px) {
                    max-width: 270px;
                }

                @media (max-width: 400px) {
                    margin: 0;
                }
            }
        }
    }
}

/* Benefits */

.benefits {
    .grid {
        position: relative;
        margin-top: 86px;
        margin-left: 89px;
        padding: 59px 178px 58px 155px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 45px 30px;
        justify-content: space-between;
        border: 1px solid $grey_dark-border;
        border-radius: 15px;
        background-image: url(../img/insurance/benefits/GridBack.png);
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 1800px) {
            padding: 55px;
        }

        @media (max-width: 1439px) {
            padding: 50px;
            padding-bottom: 41px;
            margin: 85px 70px 0;
        }

        @media (max-width: 1023px) {
            padding: 32px 28px;
            margin: 46px 0;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 30px 30px;
        }

        .item {
            padding: 0;

            .unodered-list {
                display: flex;
                align-items: center;
                max-width: 262px;

                .round {
                    padding: 19px;
                    margin-right: 17px;
                    background: rgba(255, 255, 255, 0.05);
                    background-size: 48px 48px;
                    border-radius: 50%;

                    @media (max-width: 1439px) {
                        padding: 15px;
                        background-size: 38px 38px;
                    }

                    @media (max-width: 1023px) {
                        padding: 14px;
                    }
                }

                img {
                    width: 6px;
                    height: 6px;
                }

                #item-text {
                    @include font-size(1.25, 1.2, 0);
                    color: $white;

                    @media (max-width: 1600px) {
                        @include font-size(1.125, 1.167, 0);
                    }
                }
            }
        }
    }
}

/* Reviews */

.reviews-sliders {
    .slick-slider {
        margin-top: 123px;
        max-width: 1321px;
        margin-left: 81px;

        @media (max-width: 1439px) {
            margin-left: 0;
        }

        @media (max-width: 1023px) {
            margin-top: 54px;
        }
    }

    .slick-list {
        min-height: 464px;
        overflow: visible;

        @media (max-width: 1023px) {
            min-height: auto;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 45px 30px;

        .item {
            padding: 51px 20px 0 49px;
            border: 1px solid $grey_light-border;
            border-radius: 15px;
            margin: 0 10px;
            min-height: 350px;
            align-content: space-between;

            @media (max-width: 1439px) {
                min-height: 338px;
                padding: 49px;
            }

            @media (max-width: 1320px) {
                padding: 29px;
            }

            @media (max-width: 840px) {
                padding: 15px;
            }

            @media (max-width: 767px) {
                padding: 29px;
                min-height: 260px;
            }

            @media (max-width: 380px) {
                padding: 10px;
            }

            .item-header {
                max-width: 415px;
                margin-left: 70px;
            }

            .item-content {
                max-width: 360px;

                @media (max-width: 1023px) {
                    padding-top: 55px;
                }

                #item-text {
                    @include font-size(1.313, 1.813, 0);
                }

                .review-photo {
                    width: 78px;
                    height: 78px;
                    border-radius: 50%;
                }
            }

            .item-review {
                min-height: 120px;
                @include font-size(1.125, 1.611);

                @media (max-width: 1023px) {
                    @include font-size(0.875, 1.611);
                    min-height: 100px;
                }
            }

            .items-top {
                display: grid;
                grid-template-columns: 1fr 5fr;
                grid-gap: 25px 20px;
                justify-content: space-between;

                .item-content1 {
                    display: inline-flex;
                    justify-self: start;
                    align-items: center;
                    width: 93.98px;

                    @media (max-width: 1439px) {
                        width: 78px;
                    }

                    @media (max-width: 380px) {
                        width: 50px;
                    }

                    .review-photo {
                        width: 93.98px;
                        border-radius: 50%;
                        box-shadow: 5px 5px $black;

                        @media (max-width: 1439px) {
                            max-width: 78px;
                        }

                        @media (max-width: 1023px) {
                            max-width: 60px;
                        }
                    }

                    .review-star {
                        width: 14px;
                        height: 13px;
                    }
                }

                .item-content2 {
                    display: inline-block;
                    justify-self: start;
                    align-items: center;

                    .auto {
                        display: inline-flex;
                        align-items: center;

                        .review-auto {
                            max-width: 31px;
                            max-height: 21px;
                            margin-right: 10px;
                        }

                        #item-text {
                            font-family: 'SFProDisplay', sans-serif;
                            @include font-size(1, 1.188);
                            font-weight: 400;
                            color: $yellow;
                            min-width: 200px;

                            @media (max-width: 1439px) {
                                @include font-size(0.875, 1.188);
                                min-width: 160px;
                            }
                        }
                    }
                }
            }

            .items-bottom {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-columns: 100px auto;
                grid-gap: 30px 30px;
                justify-content: space-between;
                bottom: 20px;

                @media (max-width: 1439px) {
                    bottom: 0;
                }

                .item-content2 {
                    #item-date {
                        font-family: 'SFProDisplay', sans-serif;
                        font-weight: 400;
                        color: $grey_date;
                        padding-right: 30px;
                        @include font-size(1, 1.188);

                        @media (max-width: 1439px) {
                            @include font-size(1.125, 1.611);
                        }

                        @media (max-width: 1023px) {
                            @include font-size(0.875, 1.611);
                        }
                    }
                }

                .item-content3 {
                    display: inline-flex;
                    justify-self: start;
                    align-items: center;
                    width: 35px;

                    p {
                        @include font-size(1, 1.188);
                        color: $white;
                        padding: 10px 0 10px 6px;

                        @media (max-width: 1439px) {
                            @include font-size(1.125, 1.611);
                        }

                        @media (max-width: 1023px) {
                            @include font-size(0.875, 1.611);
                        }
                    }

                    .review-photo {
                        width: 78px;
                        height: 78px;
                        border-radius: 50%;
                    }

                    .review-star {
                        width: 14px;
                        height: 13px;
                        padding-right: 5px;
                    }
                }
            }
        }

        h2 {
            padding-left: 70px;

            @media (max-width: 1023px) {
                padding: 5px 0 0;
            }
        }

        h5 {
            @include font-size(2, 1.188);
            margin: 16px 0px 1px 2px;

            @media (max-width: 1439px) {
                font-size: 1.5rem;
                margin: 1.5em 0 0.3em;
            }

            @media (max-width: 767px) {
                font-size: 1rem;
                margin: 1em 0 0.7em;
            }
        }

        .text {
            padding: 32px 0 30px;
            height: 115px;

            @media (max-width: 1600px) {
                font-size: 18px;
                height: 115px;
            }

            @media (max-width: 1439px) {
                height: 115px;
            }

            @media (max-width: 1400px) {
                font-size: 16px;
            }

            @media (max-width: 1280px) {
                font-size: 15px;
            }

            @media (max-width: 1070px) {
                font-size: 14px;
            }

            @media (max-width: 380px) {
                font-size: 13px;
            }
        }

        p {
            @include font-size(1.125, 1.611);
            color: $white;
            padding: 10px 0;

            @media (max-width: 1023px) {
                @include font-size(0.875, 1.611);
            }
        }
    }

    .slick-arrow {
        display: inline-flex;
        width: 20px;
        height: 20px;
        top: 101%;

        img {
            width: 200px;
        }

        @media (max-width: 1439px) {
            width: 20px;
            height: 20px;
            top: 100%;
        }

        @media (max-width: 1023px) {
            top: 105%;
        }

        @media (max-width: 767px) {
            top: 110%;
        }
    }

    .slick-next {
        right: 8%;

        &:before {
            content: url(../img/insurance/reviews/next.png);
            width: 40px !important;

            @media (max-width: 1439px) {
                content: url(../img/insurance/reviews/next1.png);
            }
        }

        &:hover:before {
            content: url(../img/insurance/reviews/next-white.png);

            @media (max-width: 1439px) {
                content: url(../img/insurance/reviews/next-white1.png);
            }
        }
    }

    .slick-prev {
        left: 85%;

        @media (max-width: 1439px) {
            left: 83%;
        }

        @media (max-width: 1023px) {
            left: 82%;
        }

        @media (max-width: 767px) {
            left: 80%;
        }

        @media (max-width: 600px) {
            left: 75%;
        }

        @media (max-width: 400px) {
            left: 70%;
        }

        &:before {
            content: url(../img/insurance/reviews/prev.png);

            @media (max-width: 1439px) {
                content: url(../img/insurance/reviews/prev1.png);
            }
        }

        &:hover:before {
            content: url(../img/insurance/reviews/prev-white.png);

            @media (max-width: 1439px) {
                content: url(../img/insurance/reviews/prev-white1.png);
            }
        }
    }

    .slick-dots {
        width: 18%;
        bottom: -3%;

        @media (max-width: 1439px) {
            bottom: -3%;
            width: 20%;
        }

        @media (max-width: 1023px) {
            bottom: -9%;
            width: 20%;
        }

        @media (max-width: 830px) {
            width: 35%;
        }

        li.slick-active {
            border: 1px solid $white;
            border-radius: 50%;
            color: $white;
        }
    }

    .slick-dots li {
        width: 24px;
        height: 24px;
        margin: 0 4px;

        @media (max-width: 1439px) {
            width: 20px;
            height: 20px;
        }
    }

    .slick-dots li button:before {
        font-size: 17px;
        color: $white;
        padding-top: 3px;
        padding-left: 2px;

        @media (max-width: 1439px) {
            padding-top: 0px;
            padding-left: 0px;
            font-size: 10px;
        }
    }

    .slick-dots li.slick-active button:before {
        font-size: 8px;
        opacity: 0.75;
        color: $white;

        @media (max-width: 1439px) {
            font-size: 6px;
        }
    }

    .slider-bottom {
        margin-top: 100px;
    }
}

/* Join Counters section */

.joincounters {
    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 45px 30px;
        justify-content: space-between;

        @media (max-width: 1439px) {
            margin-left: 70px;
            grid-gap: 45px 100px;
        }

        @media (max-width: 1260px) {
            grid-gap: 45px 10px;
        }

        @media (max-width: 1023px) {
            grid-gap: 30px 30px;
            margin-left: 0;
        }

        @media (max-width: 767px) {
            grid-gap: 79px 30px;
            grid-template-columns: repeat(1, 1fr);
        }

        .btn-download {
            @media (max-width: 1600px) {
                grid-template-columns: repeat(2, 180px);
            }

            @media (max-width: 1439px) {
                grid-template-columns: repeat(2, 207px);
            }

            @media (max-width: 1260px) {
                grid-template-columns: repeat(2, 150px);
            }

            @media (max-width: 1140px) {
                grid-template-columns: repeat(1, 150px);
            }

            @media (max-width: 1023px) {
                grid-template-columns: repeat(2, 150px);
            }

            @media (max-width: 830px) {
                grid-template-columns: repeat(1, 150px);
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(2, 150px);
            }

            @media (max-width: 359px) {
                grid-template-columns: repeat(1, 150px);
            }
        }

        .item {
            .item-top {
                display: inline-block;
                padding-top: 26px;

                @media (max-width: 1439px) {
                    padding: 0;
                }

                p {
                    color: $white;
                    padding: 24px 0 53px;
                    max-width: 448px;
                    font-size: 28px;
                    line-height: 140%;
                    font-weight: 400;

                    @media (max-width: 1600px) {
                        max-width: 374px;
                    }

                    @media (max-width: 1439px) {
                        @include font-size(1.5, 1.188);
                        max-width: 411px;
                        padding: 33px 0 76px;
                    }

                    @media (max-width: 1140px) {
                        padding-bottom: 25px;
                    }

                    @media (max-width: 1023px) {
                        @include font-size(0.875, 1.611);
                        width: 100%;
                        padding: 18px 0 45px;
                    }

                    @media (max-width: 840px) {
                        padding: 18px 0;
                    }

                    @media (max-width: 767px) {
                        padding: 18px 0 45px;
                    }
                }
            }

            .grid.counters {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px 0px;
                justify-content: end;
                margin-left: 110px;

                @media (max-width: 1800px) {
                    margin-left: 50px;
                }

                @media (max-width: 1650px) {
                    margin-left: 0;
                }

                @media (max-width: 1439px) {
                    grid-gap: 15px 15px;
                }

                .item-counters {
                    background-image: url('../../src/img/insurance/joinnow/bgCounters.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: 1px solid #191d22;
                    border-radius: 15px;
                    padding: 182px 34px 33px 41px;
                    max-width: 271.83px;
                    margin-right: 25px;
                    display: block;

                    @media (max-width: 1537px) {
                        padding-top: 184px;
                    }

                    @media (max-width: 1441px) {
                        padding: 182px 25px 33px 38px;
                    }

                    @media (max-width: 1439px) {
                        max-width: 228px;
                        margin-right: 0;
                    }

                    @media (max-width: 1023px) {
                        // padding: 74px 29px 29px 24px;
                        padding: 104px 5px 15px 24px;
                        min-width: 120px;
                    }

                    @media (max-width: 450px) {
                        padding: 74px 14px 29px 14px;
                        min-width: 135px;
                        margin-right: 5px;
                    }

                    @media (max-width: 360px) {
                        padding-top: 35px;
                        min-width: 84px;
                    }

                    .item-counters-bottom {
                        padding-top: 21px;
                        display: inline-flex;
                        align-items: center;

                        @media (max-width: 1537px) {
                            padding-top: 13px;
                        }

                        @media (max-width: 1023px) {
                            padding-top: 30px;
                        }

                        @media (max-width: 360px) {
                            display: block;
                        }
                    }

                    img {
                        padding-right: 8px;
                        padding-top: 1px;
                        max-width: 53px;

                        @media (max-width: 1439px) {
                            max-width: 48px;
                        }
                    }

                    .item-counters_subtitle {
                        @include font-size(1.375, 1.318);
                        color: $white;

                        @media (max-width: 1439px) {
                            @include font-size(1.125, 1.611);
                        }

                        &.tr {
                            padding-right: 8px;

                            @media (max-width: 910px) {
                                display: block;
                            }
                        }
                    }

                    .item-counters_title {
                        padding-top: 10px;
                        font-weight: 800;
                        font-size: 38px;
                        line-height: 45px;
                        text-transform: capitalize;
                        color: $white;

                        @media (max-width: 1439px) {
                            padding-top: 5px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }
                }

                .item-bloks {
                    .grid-bloks {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-gap: 25px 15px;
                        grid-template-rows: 166px 157px;

                        @media (max-width: 1537px) {
                            grid-template-rows: 160px 157px;
                        }

                        @media (max-width: 1441px) {
                            grid-template-rows: 145px 158px;
                            grid-gap: 15px 15px;
                        }

                        @media (max-width: 1023px) {
                            grid-template-rows: 105px 105px;
                        }

                        @media (max-width: 360px) {
                            grid-template-columns: 120px;
                        }

                        .item {
                            &.trusted {
                                padding: 42px 0 0 39px;

                                @media (max-width: 1537px) {
                                    padding: 41px 0 0 39px;
                                }

                                @media (max-width: 1023px) {
                                    padding: 25px 0 27px 21px;
                                }

                                @media (max-width: 460px) {
                                    padding: 25px 0 27px 19px;
                                }

                                @media (max-width: 360px) {
                                    padding: 5px 0 5px 19px;
                                }

                                @media (max-width: 300px) {
                                    padding: 20px 0 22px 5px;
                                }

                                background: $primary_black;
                                border: 1px solid $grey_light-border;
                                border-radius: 15px;

                                h4 {
                                    color: $yellow;
                                    padding-top: 16px;

                                    @media (max-width: 1023px) {
                                        font-size: 16px;
                                        line-height: 19px;
                                        padding-top: 10px;
                                    }
                                }
                            }

                            &.secure {
                                padding: 44px 0 0 39px;

                                @media (max-width: 1439px) {
                                    padding: 45px 39px 37px;
                                }

                                @media (max-width: 1023px) {
                                    padding: 25px 0 27px 21px;
                                }

                                @media (max-width: 460px) {
                                    padding: 25px 0 27px 19px;
                                }

                                @media (max-width: 300px) {
                                    padding: 20px 0 22px 5px;
                                }

                                background: $grey_dark-border;
                                border-radius: 15px;

                                p {
                                    padding-top: 11px;
                                }
                            }

                            p {
                                @media (max-width: 1920px) {
                                    @include font-size(1.375, 1.318, 0.02);
                                }

                                @media (max-width: 1023px) {
                                    font-size: 18px;
                                }

                                @media (max-width: 767px) {
                                    font-size: 14px;
                                    line-height: 29px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* FAQ - Accordions */

.accordions {
    margin: 87px 32px 0;

    @media (max-width: 1439px) {
        margin: 0;
    }

    .css-67l5gl {
        background: none !important;
        border: 1px solid #2a3038 !important;
    }

    :not(svg) {
        transform-origin: center;
    }

    .MuiPaper-root {
        margin-bottom: 24px !important;

        @media (max-width: 1439px) {
            margin-bottom: 15px !important;
        }
    }

    .MuiTypography-root {
        color: $white;
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root,
    .css-67l5gl {
        background-color: transparent !important;
        border: 1px solid #2a3038 !important;
        border-radius: 15px !important;
    }

    .css-67l5gl:first-of-type() {
        border-radius: 15px !important;
    }

    .MuiSvgIcon-root {
        background: rgba(255, 255, 255, 0.05) !important;
        border-radius: 50% !important;
        width: 27px !important;
        height: auto !important;
        padding: 5.5px !important;

        @media (max-width: 1023px) {
            width: 20px !important;
            padding: 5px !important;
        }
    }

    .MuiSvgIcon-root:hover {
        background-color: rgba(255, 255, 255, 0.2) !important;
    }

    .css-15v22id-MuiAccordionDetails-root,
    .css-u7qq7e,
    .MuiAccordionDetails-root.css-u7qq7e {
        margin: -8px 41px 32px !important;

        @media (max-width: 1439px) {
            margin: -8px 0px -3px !important;
            padding: 0 70px 50px !important;
        }

        @media (max-width: 1023px) {
            padding: 0 30px 27px !important;
            margin-top: 0 !important;
        }
    }

    .css-o4b71y-MuiAccordionSummary-content {
        margin: 0 !important;
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        margin: 0 !important;
    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root,
    .MuiButtonBase-root .MuiAccordionSummary-root .Mui-expanded .MuiAccordionSummary-gutters .css-1iji0d4.accordions,
    #panel1-header,
    #panel2-header,
    #panel3-header,
    #panel4-header,
    #panel5-header,
    #panel6-header,
    #panel7-header {
        padding: 37px 56px 37px !important;

        @media (max-width: 1439px) {
            padding: 39px 70px 36px !important;
        }

        @media (max-width: 1023px) {
            padding: 27px 30px !important;
        }
    }

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper:hover,
    .MuiAccordionSummary-expandIconWrapper.css-yw020d-MuiAccordionSummary-expandIconWrapper:hover {
        border-radius: 50% !important;
        background: rgba(255, 255, 255, 0.2);
    }

    .css-17o5nyn,
    .css-17o5nyn.Mui-expanded {
        margin: 0 !important;
    }

    .faq-title {
        font-family: 'SFProDisplay', sans-serif;
        @include font-size(1.75, 1.179, 0);
        font-weight: 800 !important;

        @media (max-width: 1439px) {
            @include font-size(1.5, 1.208);
        }

        @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 23px;
        }
    }

    .faq-answer {
        font-family: 'SFProDisplay', sans-serif;
        font-size: 20px;
        line-height: 29px;
        font-weight: 400;
        max-width: 961px;

        @media (max-width: 1439px) {
            @include font-size(1.125, 1.611, 0);
        }

        @media (max-width: 1023px) {
            font-size: 14px;
            line-height: 21px;
        }
    }

    ul {
        list-style-image: url(../img/point.png);
    }

    li {
        padding-left: 20px;
        font-family: 'SFProDisplay', sans-serif;
        @include font-size(1.125, 1.611, 0);
        font-weight: 400;

        @media (max-width: 1023px) {
            @include font-size(0.875, 1.5, 0);
        }
    }

    .faq-li {
        margin-top: 15px;
    }
}

/* Animation */

@keyframes slideUp {
    0% {
        transform: translateY(30%);
    }

    50% {
        transform: translateY(10%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(30%);
    }

    50% {
        -webkit-transform: translateY(10%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@-webkit-keyframes Appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes Appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes Appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Appearance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Video-player */
.MuiModal-root.css-79ws1d-MuiModal-root {
    border: 0;
}

.MuiBox-root.css-wm7uhr {
    // border: 0;
    div:first-child {
        width: 969px !important;
        height: 693px !important;

        @media (max-width: 1800px) {
            height: 550px !important;
        }

        @media (max-width: 1024px) {
            max-width: 767px !important;
            width: 100% !important;
            margin: 0 auto;
            top: 0;
            left: 0;
            right: 0;
            height: 360px !important;
        }

        @media (max-width: 767px) {
            max-width: 460px !important;
        }

        @media (max-width: 460px) {
            max-width: 320px !important;
        }
    }
}
