@import './_vars.scss';

.insurance_faq {
    position: relative;
    padding: 11.4rem 0 17.2rem;

    @media (max-width: 1537px) {
        padding: 9.4rem 0 5rem;
    }

    @media (max-width: 1439px) {
        padding: 10.7rem 0 6rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 2rem;
    }

    @media (max-width: 1023px) {
        padding: 4.6rem 0 7rem;
    }

    @media (max-width: 767px) {
        padding: 4.8rem 0 4rem;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0;
    }

    h2 {
        @media (max-width: 1439px) {
            padding: 0 0 76px 70px;
        }

        @media (max-width: 1023px) {
            padding: 5px 0 53px;
        }
    }
}