@import './_vars.scss';

.bookpay_services {
    position: relative;
    padding: 6.7rem 0;
    background-image: url(../img/bookpay/services/bp_services_bg.png);
    background-size: 250px 1170px;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;

    @media (max-width: 1439px) {
        padding: 8.25rem 0 7rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 1.8rem 0 6.55rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 3.9rem 0 4rem;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0;
    }

    h2 {
        padding-left: 89px;

        @media (max-width: 1439px) {
            margin-top: -5px;
            padding-left: 70px;
        }

        @media (max-width: 1300px) {
            padding-left: 105px;
        }

        @media (max-width: 1023px) {
            margin-top: 0px;
            padding: 4px 0 0;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 100px 140px;
        justify-content: space-between;
        margin-top: 120px;
        padding-left: 89px;

        @media (max-width: 1840px) {
            grid-gap: 80px 30px;
        }

        @media (max-width: 1439px) {
            margin-left: 0;
            padding-left: 70px;
            margin-top: 80px;
            grid-gap: 74px 103px;
        }

        @media (max-width: 1023px) {
            grid-gap: 30px 30px;
            padding-left: 0;
            margin-top: 55px;
        }


        @media (max-width: 767px) {
            grid-gap: 79px 30px;
            grid-template-columns: repeat(1, 1fr);
        }

        .img-wrap {
            box-sizing: border-box;

            @media (max-width: 1023px) {
                max-height: 36px;
                margin-left: 0;
            }

            @media (max-width: 767px) {}

            img {
                max-width: 91.76px;
                border-radius: 50%;
                box-shadow: 5px 5px $black;

                @media (max-width: 1439px) {
                    max-width: 78px;
                }

                @media (max-width: 1023px) {
                    max-width: 63px;
                }
            }
        }

        .text-cont {
            margin-top: 29px;

            @media (max-width: 1439px) {
                margin-top: 28px;
            }

            @media (max-width: 1023px) {
                margin-top: 56px;
            }

            .servise-title {
                p {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 29px;

                    @media (max-width: 1439px) {
                        font-size: 20px;
                    }

                    @media (max-width: 1023px) {
                        font-size: 16px;
                        line-height: 19px;
                    }
                }
            }

            .servise-subtitle {
                margin-top: 10px;
                max-width: 359px;

                @media (max-width: 1439px) {
                    max-width: 100%;
                    margin-top: 7px;
                }

                p {
                    font-size: 20px;
                    line-height: 29px;

                    @media (max-width: 1620px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1023px) {
                        font-size: 14px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}