@import './_vars.scss';

.ins_hero {
    padding: 0;
    max-height: 1052px;
    position: relative;
    overflow: visible;
    text-align: center;

    .heroZoomIn.yellow {
        color: $yellow;
    }

    .content-wrap {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1200px;
        height: 100%;
        box-sizing: border-box;
        margin: 0 auto;
        padding-top: 35px;
        text-align: center;

        @media (max-width: 1537px) {
            padding-top: 0;
        }

        @media (max-width: 767px) {
            padding: 0 2em;
        }

        .hero-img_cont {
            padding-top: 216px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;

            img {
                animation-name: Appearance;
                animation-duration: 1s;
                animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
            }

            @media (max-width: 1439px) {
                padding-top: 162px;
            }

            @media (max-width: 1023px) {
                padding-top: 82px;
            }

            @media (max-width: 767px) {
                padding-top: 16px;
            }

            .hero-img_prev {
                max-width: 77.75px;
                max-height: 99.75px;
                margin: -59px 29px 0 -82px;
                position: absolute;

                @media (max-width: 1800px) {
                    margin: -62px 29px 0 -94px;
                    max-width: 70px;
                }

                @media (max-width: 1439px) {
                    max-width: 47px;
                    max-height: 59px;
                    margin: -26px 29px 0 -45px;
                }

                @media (max-width: 1023px) {
                    max-width: 30px;
                }

                @media (max-width: 767px) {
                    width: 99%;
                    max-width: 23px;
                    margin: 48px 0 0 -30px;
                }

                @media (max-width: 440px) {
                    margin: 45px 0 0 -10px;
                }
            }

            .hero-img {
                max-width: 1000px;
                width: auto;

                @media (max-width: 1700px) {
                    max-width: 700px;
                }

                @media (max-width: 1439px) {
                    max-width: 650px;
                }

                @media (max-width: 1100px) {
                    max-width: 550px;
                }

                @media (max-width: 1023px) {
                    max-width: 358px;
                }

                @media (max-width: 767px) {
                    max-width: 339px;
                    padding-top: 57px;
                    width: 99%;
                }
            }
        }
    }

    .btn {
        padding: 24px 64px;
        position: absolute;
        top: 67%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        @media (max-width: 1023px) {
            padding: 16px 34px;
            width: 213px;
            top: 52.4%;
        }

        @media (max-width: 767px) {
            width: 204px;
            top: 39%;
            padding: 16px 0;
        }

        @media (max-width: 460px) {
            padding: 15px 25px;
            top: 34%;
        }
    }

    .bottom_subtitle {
        padding-top: 395px;
        font-size: 1.125rem;
        color: $grey;

        @media (max-width: 1537px) {
            padding-top: 313px;
        }

        @media (max-width: 1439px) {
            padding-top: 310px;
        }

        @media (max-width: 1023px) {
            padding-top: 295px;
        }

        @media (max-width: 767px) {
            padding-top: 490px;
        }

        img {
            max-width: 16px;
            display: inline;
            margin: 0 auto 0 -1%;
            padding: 0 8px 7px 0;

            @media (max-width: 1439px) {
                max-width: 13px;
            }
        }

        a {
            color: $grey;
            text-decoration: underline;
            font-size: 1.125rem;
        }
    }

    .background-line {
        position: absolute;
        visibility: visible;
        top: 175px;
        width: 95%;
        left: 0;
        right: 0;
        z-index: 0;

        &.desktop-t {
            display: none;
        }

        @media (max-width: 1639px) {
            width: 100%;
            top: 200px;
        }

        @media (max-width: 1479px) {
            top: 90px;
        }

        @media (max-width: 1023px) {
            &.desktop-d {
                display: none;
            }

            &.desktop-t {
                display: block;
                top: 220px;
            }
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }
        }
    }

    .path {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    :not(svg) {
        transform-origin: 0px 0px;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }

    .back {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;

        .img-wrap {
            position: absolute;
            z-index: 1;

            &:nth-of-type(1) {
                max-width: 660px;
                right: 0px;
                top: 42.5%;

                animation-name: slideUp;
                -webkit-animation-name: slideUp;

                animation-duration: 1s;
                -webkit-animation-duration: 1s;

                animation-timing-function: linear;
                -webkit-animation-timing-function: linear;

                @media (max-width: 1700px) {
                    width: 35%;
                }

                @media (max-width: 1439px) {
                    max-width: 500px;
                    top: 41.5%;
                }

                @media (max-width: 1023px) {
                    width: 316px;
                    top: 47%;
                }

                @media (max-width: 767px) {
                    width: 390px;
                }

                @media (max-width: 500px) {
                    max-width: 380px;
                    width: 87%;
                    top: 43%;
                }

                @media (max-width: 375px) {
                    width: 95%;
                }
            }

            &:nth-of-type(2) {
                max-width: 589px;
                top: 24px;

                animation-name: slideUp;
                -webkit-animation-name: slideUp;

                animation-duration: 1s;
                -webkit-animation-duration: 1s;

                animation-timing-function: linear;
                -webkit-animation-timing-function: linear;

                @media (max-width: 1700px) {
                    width: 35%;
                }

                @media (max-width: 1439px) {
                    max-width: 450px;
                    left: -62px;
                }

                @media (max-width: 1023px) {
                    max-width: 311px;
                    left: -22px;
                }

                @media (max-width: 767px) {
                    max-width: 370px;
                    width: 80%;
                    left: -30%;
                }

                @media (max-width: 600px) {
                    width: 75%;
                    left: -40%;
                }

                @media (max-width: 500px) {
                    width: 70%;
                    left: -40%;
                }

                @media (max-width: 400px) {
                    width: 75%;
                    left: -40%;
                }

                @media (max-width: 375px) {
                    top: 7%;
                }
            }

            &:nth-of-type(3) {
                max-width: 825px;
                width: 20%;
                left: 61px;
                top: 580px;
                z-index: 0;

                @media (max-width: 1439px) {
                    width: 36%;
                    top: 400px;
                }

                @media (max-width: 1023px) {
                    width: 26%;
                    top: 300px;
                }

                @media (max-width: 767px) {
                    width: 50%;
                }
            }

            &:nth-of-type(4) {
                z-index: 0;
                max-width: 800px;
                right: 0;
                top: -168px;

                @media (max-width: 1023px) {
                    top: 0;
                    max-width: 350px;
                }

                @media (max-width: 767px) {
                    max-width: 300px;
                    right: -30px;
                }
            }
        }
    }
}
