@import './_vars.scss';

.bookpay_slider {
    margin: 5.15rem 0 7rem;
    padding: 0;

    @media (max-width: 1439px) {
        margin: 12.1rem 0 7rem;
    }

    @media (max-width: 1280px) {
        margin: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        margin: 7.8rem 0 5rem;
    }

    @media (max-width: 767px) {
        margin: 6rem 0 4rem;
    }

    @media (max-width: 479px) {
        margin: 4.5rem 0;
    }

    .slider-section_header {
        .item-header {
            @media (max-width: 1400px) {
                max-width: 440px;
            }

            @media (max-width: 1100px) {
                font-size: 40px;
                line-height: 59px;
            }

            @media (max-width: 1023px) {
                font-weight: 800;
                font-size: 30px;
                line-height: 36px;
            }

            @media (max-width: 900px) {
                max-width: 350px;
            }

            @media (max-width: 767px) {
                max-width: 555px;
            }

            @media (max-width: 540px) {
                max-width: 330px;
            }

            @media (max-width: 370px) {
                max-width: 310px;
            }
        }
    }

    .slider-section {
        .slider-section_header {
            @media (max-width: 767px) {
                width: 500px;
                padding-bottom: 0px;
            }

            @media (max-width: 400px) {
                width: 380px;
            }

            h2 {
                @media (max-width: 420px) {
                    font-size: 23px;
                }
            }
        }

        input[type=range] {
            left: 13.7%;
            width: 157px;
            top: 38.5%;

            @media (max-width: 1700px) {
                left: 15.5%;
            }

            @media (max-width: 1639px) {
                left: 10.8%;
            }

            @media (max-width: 1600px) {
                left: 11%;
            }

            @media (max-width: 1560px) {
                left: 11.3%;
            }

            @media (max-width: 1520px) {
                left: 11.7%;
            }

            @media (max-width: 1500px) {
                left: 11.9%;
            }

            @media (max-width: 1480px) {
                left: 12%;
            }

            @media (max-width: 1439px) {
                top: 36.8%;
                left: 10.9%;
            }

            @media (max-width: 1366px) {
                left: 11.7%;
            }

            @media (max-width: 1333px) {
                top: 37%;
                left: 12%;
            }

            @media (max-width: 1330px) {
                top: 42%;
            }

            @media (max-width: 1300px) {
                left: 12.3%;
            }

            @media (max-width: 1200px) {
                left: 13.2%;
            }

            @media (max-width: 1156px) {
                top: 42%;
                left: 13.9%;
            }

            @media (max-width: 1100px) {
                left: 14.5%;
            }

            @media (max-width: 1023px) {
                top: 12%;
                left: 1%;
            }

            @media (max-width: 850px) {
                left: 1.5%;
            }

            @media (max-width: 767px) {
                top: -8.3%;
                left: -5%;
            }

            @media (max-width: 720px) {
                top: -6%;
                left: -5.2%;
            }

            @media (max-width: 640px) {
                left: -6.2%;
            }

            @media (max-width: 568px) {
                left: -7%;
            }

            @media (max-width: 480px) {
                left: -9%;
            }

            @media (max-width: 400px) {
                top: -13%;
                left: -1%;
                width: 90px;
            }
        }

        .grid {
            @media (max-width: 767px) {
                grid-gap: 0;
            }

            .item {

                &:nth-of-type(2) {
                    @media (max-width: 767px) {
                        justify-self: center;
                    }
                }

                h3 {

                    @media (max-width: 1156px) {
                        @include font-size(2, 1.194, 0);
                    }

                    @media (max-width: 1023px) {
                        font-size: 24px;
                        line-height: 29px;
                    }

                    @media (max-width: 421px) {
                        font-size: 20px;
                        line-height: 27px;
                        margin-top: 13px;
                    }

                    @media (max-width: 320px) {
                        font-size: 19px;
                        line-height: 24px;
                    }
                }

                #item-text {

                    @media (max-width: 1156px) {
                        @include font-size(1, 1.611, 0);
                    }

                    @media (max-width: 800px) {
                        font-size: 15px;
                        line-height: 22px;
                    }

                    @media (max-width: 767px) {
                        padding: 30px 0 0;
                        max-width: 355px;
                        font-size: 14px;
                    }

                    @media (max-width: 540px) {
                        padding: 20px 0 0;
                    }

                    @media (max-width: 395px) {
                        line-height: 20px;
                    }

                    @media (max-width: 375px) {
                        max-width: 277px;
                        padding: 10px 0 0;
                    }
                }

                .item-content {
                    @media (max-width: 767px) {
                        padding-top: 92px;
                        max-width: 355px;
                        padding-left: 65px;
                    }

                    @media (max-width: 720px) {
                        padding-top: 102px;
                    }

                    @media (max-width: 540px) {
                        padding-top: 106px;
                    }

                    @media (max-width: 480px) {
                        padding-top: 98px;
                    }

                    @media (max-width: 375px) {
                        max-width: 240px;
                    }

                    @media (max-width: 320px) {
                        max-width: 215px;
                    }
                }

                .img-wrap {
                    max-height: 684px;

                    @media (max-width: 1439px) {
                        height: 623px;
                        width: 633px;
                    }

                    @media (max-width: 1330px) {
                        max-height: 540px;
                        max-width: 420px;
                    }

                    @media (max-width: 1023px) {
                        background-size: 335px 284px;
                        margin-top: 0;
                        max-height: 425px;
                        max-width: 335px;
                    }

                    @media (max-width: 767px) {
                        max-height: 452px;
                    }

                    @media (max-width: 720px) {
                        max-height: 390px;
                        margin: 0 auto;
                    }
                }

                img {
                    @media (max-width: 1330px) {
                        max-width: 330px;
                    }

                    @media (max-width: 1023px) {
                        max-width: 270px;
                    }

                    @media (max-width: 767px) {
                        margin-top: 30px;
                    }

                    @media (max-width: 720px) {
                        margin: 0 auto;
                    }
                }
            }
        }

        .slick-dots {
            @media (max-width: 1695px) {
                bottom: 0%;
                margin: 0 64px 0;
            }

            @media (max-width: 1439px) {
                bottom: 4%;
                margin: 0 64px 0;
            }

            @media (max-width: 1330px) {
                bottom: 0%;
                margin: 0;
            }

            @media (max-width: 767px) {
                bottom: 51%;
            }

            li {
                @media (max-width: 1439px) {
                    margin-top: 55px;
                }
            }
        }

        .slick-list {
            @media (max-width: 1023px) {
                height: 425px !important;
            }

            @media (max-width: 767px) {
                height: 693px !important;
            }

            @media (max-width: 540px) {
                height: 615px !important;
            }
        }
    }
}