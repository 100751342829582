@import './_vars.scss';

.insurance_slider {
    margin: 8.6rem 0 7rem;
    padding: 0;

    @media (max-width: 1439px) {
        margin: 9.8rem 0 7rem;
    }

    @media (max-width: 1280px) {
        margin: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        margin: 2.3rem 0 1rem;
    }

    @media (max-width: 767px) {
        margin: 0rem 0 4rem;
    }

    .slider-section {
        background-image: url(../img/insurance/slider/InsSliderBg.png);
        background-size: 326px 520px;
        background-repeat: no-repeat;
        background-position: right;
        background-position-y: bottom;

        @media (max-width: 1639px) {
            padding: 0 135px;
        }

        @media (max-width: 1023px) {
            padding: 0 70px;
        }

        @media (max-width: 767px) {
            padding: 0 $pad_md;
        }

        .slider-section_header {
            width: 735px;
            position: absolute;
            z-index: 2;

            @media (max-width: 1660px) {
                width: 620px;
            }

            @media (max-width: 1639px) {
                width: 640px;
            }

            @media (max-width: 1350px) {
                width: 555px;
            }

            @media (max-width: 1280px) {
                width: 500px;
            }

            @media (max-width: 1200px) {
                width: 480px;
            }

            @media (max-width: 1150px) {
                width: 440px;
            }

            @media (max-width: 1100px) {
                width: 410px;
                padding-bottom: 10px;
            }

            @media (max-width: 1023px) {
                width: 380px;
                padding-bottom: 5px;
            }

            @media (max-width: 900px) {
                width: 345px;
            }

            @media (max-width: 800px) {
                width: 330px;
            }

            @media (max-width: 767px) {
                width: 380px;
                padding-bottom: 2px;
            }

            @media (max-width: 400px) {
                width: 380px;
                padding-bottom: 1px;
            }

            @media (max-width: 374px) {
                width: 320px;
                padding-bottom: 1px;
            }

            h6 {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                margin-top: 91px;

                @media (max-width: 1639px) {
                    @include font-size(1, 1.188, 0);
                }

                @media (max-width: 1537px) {
                    margin-top: 11px;
                }

                @media (max-width: 1023px) {
                    margin-top: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                }

                @media (max-width: 767px) {
                    margin-top: 0;
                }
            }

            .item-header {
                max-width: 800px;
                padding-left: 89px;
                font-weight: 800;
                font-size: 58px;
                line-height: 69px;

                @media (max-width: 1639px) {
                    font-size: 3rem;
                }

                @media (max-width: 1500px) {
                    font-size: 48px;
                    line-height: 59px;
                    margin-left: 0;
                }

                @media (max-width: 1439px) {
                    padding-left: 70px;
                }

                @media (max-width: 1200px) {
                    padding: 4px 0 0;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 36px;
                }

                @media (max-width: 767px) {
                    max-width: 415px;
                    margin-left: 0;
                }

                @media (max-width: 395px) {
                    font-size: 28px;
                }
            }
        }

        input[type=range] {
            left: 11.3%;
            top: 34.5%;
            width: 251px;

            @media (max-width: 1787px) {
                left: 12%;
            }

            @media (max-width: 1720px) {
                left: 12.6%;
            }

            @media (max-width: 1639px) {
                left: 8%;
            }

            @media (max-width: 1600px) {
                left: 8.5%;
            }

            @media (max-width: 1560px) {
                left: 8.3%;
            }

            @media (max-width: 1520px) {
                left: 8.6%;
            }

            @media (max-width: 1500px) {
                left: 8.7%;
            }

            @media (max-width: 1480px) {
                left: 8.8%;
            }

            @media (max-width: 1439px) {
                top: 33.4%;
                left: 7.9%;
            }

            @media (max-width: 1366px) {
                left: 8.2%;
            }

            @media (max-width: 1333px) {
                left: 8.4%;
            }

            @media (max-width: 1300px) {
                left: 8.6%;
            }

            @media (max-width: 1200px) {
                top: 13.5%;
                left: 3.5%;
            }

            @media (max-width: 1023px) {
                top: 12%;
                left: -3.3%;
            }

            @media (max-width: 850px) {
                left: -4.1%;
            }

            @media (max-width: 767px) {
                width: 200px;
                top: -1%;
                left: -8%;
            }

            @media (max-width: 650px) {
                left: -8%;
            }

            @media (max-width: 600px) {
                left: -9%;
            }

            @media (max-width: 568px) {
                left: -10%;
            }

            @media (max-width: 510px) {
                left: -12%;
            }

            @media (max-width: 480px) {
                left: -13%;
            }

            @media (max-width: 450px) {
                left: -14%;
            }

            @media (max-width: 400px) {
                left: -17%;
            }

            @media (max-width: 395px) {
                top: 0%;
            }

            @media (max-width: 376px) {
                left: -18%;
            }

            @media (max-width: 360px) {
                left: -20%;
            }
        }

        .grid {
            @media (max-width: 767px) {
                grid-gap: 20px;
            }

            .item {

                &:nth-of-type(2) {
                    @media (max-width: 767px) {
                        justify-self: center;
                    }
                }

                .item-content {
                    padding-top: 305px;

                    @media (max-width: 1439px) {
                        padding-left: 165px;
                    }

                    @media (max-width: 1200px) {
                        padding-top: 175px;
                        padding-left: 82px;
                    }

                    @media (max-width: 1023px) {
                        max-width: 277px;
                        padding-top: 145px;
                        padding-left: 62px;
                    }

                    @media (max-width: 767px) {
                        padding-top: 100px;
                        max-width: 308px;
                        padding-left: 65px;
                    }

                    @media (max-width: 400px) {
                        padding-left: 35px;
                    }

                    @media (max-width: 360px) {
                        padding-top: 75px;
                    }

                    h3 {
                        font-weight: 800;
                        font-size: 40px;
                        line-height: 48px;

                        @media (max-width: 1500px) {
                            font-size: 30px;
                            line-height: 36px;
                        }

                        @media (max-width: 1200px) {
                            font-size: 26px;
                            line-height: 30px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 24px;
                            line-height: 29px;
                        }

                        @media (max-width: 767px) {
                            margin-top: 20px;
                        }

                        @media (max-width: 395px) {
                            font-size: 22px;
                            line-height: 27px;
                        }
                    }

                    #item-text {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 29px;

                        @media (max-width: 1500px) {
                            font-size: 18px;
                            line-height: 24px;
                        }

                        @media (max-width: 1200px) {
                            @include font-size (1, 1.813, 0)
                        }

                        @media (max-width: 800px) {
                            font-size: 15px;
                            line-height: 22px;
                        }

                        @media (max-width: 767px) {
                            padding: 30px 0 0;
                            font-size: 14px;
                        }

                        @media (max-width: 395px) {
                            line-height: 20px;
                        }
                    }
                }

                .img-wrap {
                    @media (max-width: 767px) {
                        margin: 0;
                        max-height: 460px;
                        background-image: none;
                    }
                }
            }
        }

        .slick-list {
            @media (max-width: 767px) {
                height: 754px !important;
            }
        }
    }
}