@import './_vars';

/* Header */
header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 52px 0 0;

  @media (max-width: 1439px) {
    padding: 50px 0 0;
  }

  @media (max-width: 1279px) {
    padding: 30px 0;
  }

  @media (max-width: 1023px) {
    padding: 50px 0 10px;
  }

  @media (max-width: 767px) {
    padding: 25px 0 0;
  }

  .cont_lg {
    max-width: 1300px;

    @media (max-width: 1639px) {
      padding: 0 100px;
    }

    @media (max-width: 1023px) {
      padding: 0 70px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .item {
    flex: 1;

    @media (max-width: 1279px) {
      //   flex-basis: 360px;
    }

    &:nth-of-type(1) {
      @media (max-width: 1023px) {
        order: 1;
        flex: 85px 1 auto;
        z-index: 1001;
      }
    }

    &:nth-of-type(2) {
      flex: 0 1 auto;
      //   width: 685px;
      margin: 0 auto;

      @media (max-width: 1279px) {
        // width: 1000px;
      }

      @media (max-width: 1023px) {
        flex: 1 1 auto;
        order: 2;
      }
    }

    &:nth-of-type(3) {
      text-align: end;
      align-items: end;
      justify-content: end;
      margin-left: auto;

      @media (max-width: 1023px) {
        visibility: hidden;
        order: 3;
      }
    }
  }
}

.btn-lng {
  padding: 0;
  margin-right: 15px;
  border: 0px solid $black;
  background: none;
  width: 20px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  filter: grayscale(100%);
  cursor: pointer;

  @media (max-width: 1439px) {
    max-width: 20px;
    max-height: 20px;
  }
}

.btn-lng:hover {
  filter: grayscale(0%);
}

.btn-color {
  padding: 0;
  margin-right: 15px;
  border: 0px solid $black;
  background: none;
  max-width: 20px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  filter: grayscale(0%);
  cursor: pointer;

  @media (max-width: 1439px) {
    max-width: 20px;
    max-height: 20px;
  }
}

.logo {
  max-width: 85px;
  vertical-align: middle;

  @media (max-width: 1439px) {
    max-width: 84.53px;
  }

  @media (max-width: 767px) {
    width: 64px;
  }
}

// Navigation
nav {
  ul {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0;

    @media (max-width: 1023px) {
      display: block;
      justify-content: center;
    }
  }

  li + li {
    margin-left: 1em;

    @media (max-width: 1023px) {
      margin-left: 1.5em;
    }

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  li {
    display: inline-flex;
    margin: 0 15px;
    position: relative;

    @media (max-width: 1439px) {
      margin: 0 20px;
    }

    #nav-hidden {
      visibility: hidden;
    }

    &.nav-visible {
      visibility: visible;
      color: $yellow;

      &:after {
        content: '';
        height: 2px;
        border-radius: 4px;
        background-color: #f7b501;
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;

        @media (max-width: 1023px) {
          visibility: hidden;
        }
      }

      .active {
        color: $yellow;
        text-shadow: 5px 5px 0px black;
      }
    }
  }

  a {
    color: #fff;
    position: relative;
    z-index: 1;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    @media (max-width: 1439px) {
      padding-top: 0.5em;
      font-size: 0.813rem;
    }

    &:hover {
      color: #f7b500;
    }

    @media (max-width: 768px) {
      padding: 0.3em 0;
      color: $white;

      &:after {
        background-color: $white;
      }
    }
  }

  .btn_mob {
    display: none;
  }
}

/* MOBILE_MENU */
#nav-visible {
  line-height: 0;
  margin-top: 8px;

  @media (max-width: 1439px) {
    margin-top: 14px;
  }
}

@media (max-width: 1023px) {
  .nav {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 400px;
    text-align: start;
    margin-top: 0;
    padding: 0;
    background-color: $primary_black;
    border-radius: 0;
    overflow: hidden;
    z-index: 1000;
    will-change: width, transform, transition, border-radius;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.8s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;

    a {
      padding-top: 0;
    }

    @media (max-width: 767px) {
      justify-content: inherit;
    }
  }

  .nav.is-active {
    border-radius: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    position: fixed;
    overflow: visible;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: url(../img/navi_back.png);

    &:before {
      content: '';
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      background-image: url(../img/hamburger-bg1.png);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 348px;

      @media (max-width: 767px) {
        transform: rotate(180deg);
        bottom: 42%;
        right: 55%;
        background-position: left top;
      }
    }
  }

  .nav.is-active .menu {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .nav.is-active .menu a:after {
    background: #fff;
  }

  .nav ul {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 240px 70px 70px;

    &::after {
      content: '';
      background-image: url(../img/hamburger-bg2.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;

      @media (max-width: 767px) {
        background-position: right bottom;
        background-size: contain;
      }
    }

    @media (max-width: 1023px) {
      padding: 240px 70px 62px;
    }

    @media (max-width: 950px) {
      padding: 170px 70px 62px;
    }

    @media (max-width: 767px) {
      padding: 118px 30px 15px;
    }
  }

  .nav li {
    display: block;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 0;
      line-height: 0;
    }
  }

  .nav li a {
    font-size: 2.25rem;
    font-weight: 800;

    @media (max-width: 767px) {
      font-size: 1.875rem;
    }
  }

  .nav li + li {
    margin: 22px 0;
  }

  .nav .btn_mob {
    margin-top: 53px;

    @media (max-width: 767px) {
      margin-top: 70px;
    }
  }

  .nav .btn_mob a {
    display: inline-block;
  }

  .nav .btn_mob a:after {
    display: none;
  }

  .nav .btn_mob a:hover {
    color: #f7b500;
    background: #000;
  }

  .nav .btn_mob-download {
    padding-top: 442px;

    @media (max-width: 950px) {
      padding-top: 140px;
    }

    @media (max-width: 350px) {
      padding-top: 60px;
    }

    .grid-bottom {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 45px 30px;
      justify-content: end;
      align-items: center;

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 33px;
      }

      a {
        font-size: 0;
        font-weight: 400;
        padding: 0;
        text-transform: none;
      }

      .btn-download {
        @media (max-width: 767px) {
          justify-content: center;
        }

        @media (max-width: 350px) {
          grid-template-columns: repeat(1, 1fr);
          justify-items: center;
        }
      }

      .item-bottom.social {
        justify-self: end;

        @media (max-width: 767px) {
          justify-self: center;
          display: inline-grid;
        }

        .mail {
          @media (max-width: 767px) {
            justify-self: center;
            text-align: center;
            padding-bottom: 300px;
          }

          a {
            justify-self: center;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
}

/*  Hamburger   */
.hamburger {
  display: none;
}

@media (max-width: 1023px) {
  .hamburger {
    display: block;
    position: relative;
    z-index: 2000;
    line-height: 0.5em;
    right: 0;
    padding-right: 0;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    max-width: 28px;
  }
}

.hamburger .hamburger-box {
  width: 35px;
}

#nav-icon {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 71%;
  right: 70px;
  will-change: transform;
  -webkit-transform: translateY(-50%) rotate(0deg);
  transform: translateY(-50%) rotate(0deg);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  cursor: pointer;
  opacity: 1;

  @media (max-width: 767px) {
    right: 20px;
    left: auto;
  }
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 12px;
  background: $yellow;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  margin: 8px;
}

#nav-icon span:nth-child(1) {
  top: 0;
}

#nav-icon span:nth-child(2) {
  top: 5px;
}

#nav-icon span:nth-child(3) {
  top: 10px;
}

#nav-icon.open span {
  background: $yellow;
  margin: 6px 8px;
}

#nav-icon.open span:nth-child(1) {
  top: 7px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon.open span:nth-child(3) {
  top: 7px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

nav a:hover {
  color: $yellow;
}
