@import './_vars.scss';

.bookpay_reviews {
  padding: 0.9rem 0 4.2rem;
  position: relative;

  .background-line {
    position: absolute;
    top: 200px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 0;

    &.desktop-t {
      display: none;
    }

    &.desktop-m {
      display: none;
    }

    @media (max-width: 1639px) {
      top: 160px;
    }

    @media (max-width: 1023px) {
      &.desktop-d {
        display: none;
      }

      &.desktop-t {
        display: block;
        top: 100px
      }
    }

    @media (max-width: 767px) {
      &.desktop-t {
        display: none;
      }

      &.desktop-m {
        display: block;
        top: 50px
      }
    }
  }

  .path {
    stroke-dasharray: 5;
    -webkit-animation: dash 40s linear;
    animation: dash 40s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  :not(svg) {
    transform-origin: 0px 0px;
  }

  @keyframes dash {
    100% {
      stroke-dashoffset: 1000;
    }
  }

  @media (max-width: 1439px) {
    padding: 4.7rem 0 7rem;
  }

  @media (max-width: 1280px) {
    padding: 6.5rem 0;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 0 5.3rem;
    background-size: 490px 730px;
  }

  @media (max-width: 767px) {
    padding: 3.9rem 0 4rem;
  }

  @media (max-width: 479px) {
    padding: 4.5rem 0;
  }

  .cont {
    @media (max-width: 1439px) {
      padding: 0 68px 0 135px;
    }

    @media (max-width: 1023px) {
      padding: 0 70px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .item {
    background-color: $primary_black;
  }

  .img-wrap {
    box-sizing: border-box;
    width: auto;
    margin: 9px 0 0 92px;
    display: inline-flex;

    @media (max-width: 1439px) {
      margin: 0 0 0 72px;
    }

    @media (max-width: 1023px) {
      max-height: 36px;
      margin-left: 0;
    }

    @media (max-width: 767px) {}

    img {
      width: auto;
      max-height: 53px;
      margin: 7px 3px 0;

      @media (max-width: 1639px) {
        margin: 0 3px;
      }

      @media (max-width: 1023px) {
        max-height: 33px;
      }
    }
  }

  .reviews-sliders {
    .grid {
      h5 {
        margin: 10px 0 0;

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .item {
        @media (max-width: 1439px) {
          min-height: 336px;
          padding-bottom: 9px;
          padding-top: 49px;
        }

        @media (max-width: 1023px) {
          min-height: 308px;
          margin: 0 10px;
          padding: 28px 28px 14px;
        }

        @media (max-width: 820px) {
          padding: 28px 15px 14px;
        }

        @media (max-width: 767px) {
          min-height: 250px;
        }

        .items-top {
          @media (max-width: 1439px) {
            grid-gap: 25px 30px;
          }

          @media (max-width: 1023px) {
            grid-gap: 30px 0px;
          }

          @media (max-width: 767px) {
            grid-gap: 30px 15px;
          }

          .item-content2 {
            .auto {
              #item-text {
                @media (max-width: 1023px) {
                  font-size: 14px;
                  line-height: 17px;
                }
              }
            }
          }
        }

        .items-bottom {
          .item-content3 {
            @media (max-width: 1439px) {
              p {
                font-size: 0.875rem;
              }
            }
          }

          .item-content2 {
            @media (max-width: 1439px) {
              #item-date {
                font-size: 0.875rem;
              }
            }
          }
        }
      }

      .text {
        @media (max-width: 1439px) {
          padding: 21px 0 35px;
        }

        @media (max-width: 1439px) {
          padding: 20px 0 35px;
          font-size: 18px;
          line-height: 29px;
        }

        @media (max-width: 1023px) {
          font-size: 14px;
          line-height: 25px;
          padding-top: 33px;
        }

        @media (max-width: 460px) {
          padding: 10px 0 5px;
        }
      }
    }

    .slick-slider {
      @media (max-width: 1439px) {
        margin: 132px 0 0 60px;
      }

      @media (max-width: 1023px) {
        margin: 55px 0 0;
      }

      .slick-list {
        @media (max-width: 1023px) {
          height: 422px !important;
        }
      }
    }

    .slick-dots {
      @media (max-width: 1439px) {
        bottom: 8.8%;
        width: 14.5%;
      }

      @media (max-width: 1023px) {
        bottom: 8.8%;
        width: 20%;
      }

      li {
        @media (max-width: 1439px) {
          width: 16px;
          height: 16px;
        }

        button:before {
          @media (max-width: 1439px) {
            width: 16px;
          }
        }

      }

      li.slick-active {
        button:before {
          @media (max-width: 1439px) {
            font-size: 5px;
            line-height: 17px;
          }
        }
      }

    }

    .slick-arrow {
      @media (max-width: 1439px) {
        top: 89.4%;
      }
    }

    .slick-prev {
      @media (max-width: 1439px) {
        left: 86%;
        z-index: 2;
      }

      @media (max-width: 1023px) {
        left: 82%;
      }

      @media (max-width: 767px) {
        left: 78%;
      }

      @media (max-width: 500px) {
        left: 70%;
      }
    }

    .slick-next {
      @media (max-width: 1439px) {
        right: 6.5%;
      }
    }
  }
}