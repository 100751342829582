@import './_vars.scss';

.insurance_joinnow {
    position: relative;
    padding: 4.35rem 0 7.35;
    margin-left: 90px;

    @media (max-width: 1537px) {
        padding: 9rem 0 7rem;
        margin: 0 auto;
    }

    @media (max-width: 1439px) {
        padding: 12.8rem 0 7rem;
        margin: 0 auto;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 8rem 0 6.55rem;
    }

    @media (max-width: 767px) {
        padding: 6.2rem 0 3rem;
    }

    .joincounters .grid .item .grid.counters .item-bloks .grid-bloks {
        grid-template-rows: 168px 166px;

        @media (max-width: 1537px) {
            grid-template-rows: 165px 166px;
        }

        @media (max-width: 1023px) {
            grid-template-rows: 105px 105px;
        }

        .item.trusted {
            @media (max-width: 890px) {
                padding: 15px 0 15px 20px;
            }
        }

        .item.secure {
            @media (max-width: 890px) {
                padding: 25px 0 15px 20px;
            }
        }
    }

    .item-counters {
        z-index: 3;

        &:before {
            position: absolute;
            content: ' ';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/insurance/joinnow/insurance_join_rectangle.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            opacity: 0.2;
            z-index: 1;
        }
    }

    .item-counters-bottom {
        @media (max-width: 1023px) {
            padding-top: 5px !important;
            flex-wrap: wrap;
        }
    }
}