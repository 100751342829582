@import './_vars.scss';

.parking_partners {
    position: relative;
    padding: 150px 0 90px;
    background-size: 250px 1170px;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;

    @media (max-width: 1537px) {
        padding: 150px 0 127px;
    }

    @media (max-width: 1439px) {
        padding: 4.25rem 0 7rem;
    }

    @media (max-width: 1280px) {
        padding: 3.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 1.2rem 0 7.1rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 0rem 0 5rem;
    }

    @media (max-width: 479px) {
        padding: 0 0 1rem;
    }

    h2 {
        @media (max-width: 1439px) {
            margin-top: -5px;
        }

        @media (max-width: 1366px) {
            font-size: 2.188rem;
        }

        @media (max-width: 1023px) {
            font-size: 1.875rem;
            margin-top: 0px;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 100px 140px;
        justify-content: space-between;

        @media (max-width: 1740px) {
            grid-gap: 30px 30px;
        }

        @media (max-width: 1023px) {
            grid-template-columns: repeat(1, 1fr);
        }

        @media (max-width: 767px) {
            grid-gap: 40px 30px;
        }

        .grid {
            display: grid;
            grid-gap: 15px;
            justify-content: space-between;

            .first,
            .third,
            .forth {
                display: inline-flex;
                background-color: $primary_black;
                border: 1px solid $grey_light-border;
                border-radius: 15px;
                padding: 37.5px 45px 34px;
                align-items: center;
                z-index: 4;

                @media (max-width: 1023px) {
                    background-color: $primary_black;
                }

                @media (max-width: 767px) {
                    padding: 39px 25px;
                }
            }

            .second {
                z-index: 4;
                display: inline-flex;
                background-color: $primary_black;
                border: 1px solid $grey_light-border;
                border-radius: 15px;
                padding: 50px 45px 48px;
                align-items: center;

                @media (max-width: 1023px) {
                    background-color: $primary_black;
                }

                @media (max-width: 767px) {
                    padding: 31px 25px;
                }
            }

            .img-wrap {

                img {
                    width: 48px;
                    display: inline;
                    padding-right: 25px;
                }
            }

            .text-cont {
                display: block;

                .partners-title {
                    display: inline-flex;
                    vertical-align: middle;

                    @media (max-width: 1550px) {
                        display: block;
                    }

                    @media (max-width: 767px) {
                        display: inline-flex;
                    }

                    p {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 29px;

                        @media (max-width: 1660px) {
                            font-size: 20px;
                        }

                        @media (max-width: 1380px) {
                            font-size: 18px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 16px;
                            line-height: 19px;
                        }
                    }
                }

                .partners-subtitle {
                    margin-top: 10px;
                    max-width: 359px;

                    @media (max-width: 1439px) {
                        max-width: 100%;
                        margin-top: 7px;
                    }

                    p {
                        font-size: 20px;
                        line-height: 29px;

                        @media (max-width: 1620px) {
                            font-size: 18px;
                        }

                        @media (max-width: 1023px) {
                            font-size: 14px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}