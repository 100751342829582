@import './_vars.scss';

section .bookpay_hero {
    padding-top: 0px;
}

.bookpay_hero {
    padding: 0;
    height: 1045px;
    position: relative;
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (max-width: 1537px) {
        height: 891px;
    }

    @media (max-width: 1439px) {
        height: auto;
    }

    .cont {
        margin: 0;
    }

    .content-wrap {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: start;
        max-width: 750px;
        box-sizing: border-box;
        text-align: start;
        float: left;
        padding: 250px 0 282px;

        @media (max-width: 1537px) {
            padding: 159px 0 188px;
            // max-width: 720px;
        }

        @media (max-width: 1439px) {
            padding: 171px 0 300px;
            max-width: 720px;
        }

        @media (max-width: 1200px) {
            max-width: 515px;
        }

        @media (max-width: 1023px) {
            max-width: 370px;
            padding: 110px 0 293px;
        }

        @media (max-width: 767px) {
            max-width: 100%;
            padding: 34px 0 606px;
        }

        .heroZoomIn.yellow {
            color: $yellow;
            margin: 0;
            text-shadow: 10px 10px 0px rgba(0, 0, 0, 100%);

            @media (max-width: 1439px) {
                text-shadow: 7px 7px 0px rgba(0, 0, 0, 100%);
            }

            @media (max-width: 1023px) {
                text-shadow: 4px 4px 0px rgba(0, 0, 0, 25%);
            }
        }

        h1 {
            padding-bottom: 0;

            span {
                position: relative;
                white-space: nowrap;
            }

            @media (max-width: 1200px) {
                font-size: 54px;
                line-height: 76px;
            }

            @media (max-width: 1023px) {
                @include font-size(2.25, 1.194, 0);
                padding-bottom: 0;
            }

            @media (max-width: 767px) {
                font-size: 1.875rem;
            }
        }

        .btn {
            max-width: 262px;
            min-width: 244px;
            position: absolute;
            top: 77.5%;
            padding: 1.15em 1.625em;

            @media (max-width: 1537px) {
                top: 80.5%;
            }

            @media (max-width: 1439px) {
                max-width: 245px;
                padding: 1.35em 1.6em;
            }

            @media (max-width: 1023px) {
                max-width: 204px;
                min-width: 204px;
                padding: 1.15em 1.6em;
            }

            @media (max-width: 767px) {
                top: 86%;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }

    .bp_bottom_subtitle {
        font-size: 1.125rem;
        color: $grey;
        text-align: center;
        margin: 0 auto;
        z-index: 1;

        @media (max-width: 1023px) {
            font-size: 14px;
        }

        img {
            max-width: 16px;
            display: inline;
            margin: 0 auto 0 -1%;
            padding: 0 8px 7px 0;

            @media (max-width: 1439px) {
                max-width: 13px;
            }
        }

        a {
            color: $grey;
            font-size: 1.125rem;
            text-decoration: underline;

            @media (max-width: 1439px) {
                font-size: 16px;
                line-height: 19px;
            }

            @media (max-width: 1023px) {
                font-size: 14px;
            }
        }
    }

    .scroll-wrap {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        fill: white;

        a {
            color: $white !important;
        }

        @media (max-width: 1023px) {
            bottom: 0;
        }
    }

    .scroll_btn {
        animation: arrow-bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);

        &:hover {
            animation-play-state: paused;
        }

        img {
            width: 25px;
            color: $white;

            @media (max-width: 767px) {
                width: 18px;
            }
        }
    }

    @keyframes arrow-bounce {
        50% {
            transform: translateY(-15px);
        }
    }

    .bp_back {
        .bp_img-wrap {
            position: absolute;
            z-index: 1;

            &.bp_mobile {
                max-width: 330px;
                right: 14%;
                top: 10%;
                z-index: 7;

                -webkit-animation: bounce-down 5s linear infinite;
                animation: bounce-down 5s linear infinite;

                @media (max-width: 1639px) {
                    // width: 250px;
                    width: 330px;
                }

                @media (max-width: 1537px) {
                    top: 4%;
                }

                @media (max-width: 1023px) {
                    max-width: 229px;
                    right: 7%;
                    top: 0;
                }

                @media (max-width: 767px) {
                    width: 213px;
                    top: 18%;
                    left: 0;
                    right: 0;
                    margin: auto;
                }

                @media (max-width: 529px) {
                    top: 22%;
                }
            }

            &.bp_tools1 {
                max-width: 38px;
                right: 14%;
                top: 12%;
                z-index: 1;

                -webkit-animation: bounce-up 5s linear infinite;
                animation: bounce-up 5s linear infinite;

                @media (max-width: 1023px) {
                    right: 10%;
                    top: 3%;
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }

            &.bp_tools2 {
                max-width: 38px;
                right: 33%;
                top: 53%;
                z-index: 1;

                -webkit-animation: bounce-down 6s linear infinite;
                animation: bounce-down 6s linear infinite;

                @media (max-width: 1800px) {
                    right: 36%;
                }

                @media (max-width: 1639px) {
                    top: 45%;
                    right: 33%;
                }

                @media (max-width: 1439px) {
                    top: 51%;
                }

                @media (max-width: 1389px) {
                    top: 63%;
                    right: 37%;
                }

                @media (max-width: 1023px) {
                    right: 57%;
                    top: 74%;
                }

                @media (max-width: 767px) {
                    top: 54%;
                    left: -2%;
                }
            }

            &.bp_tools3 {
                max-width: 38px;
                right: 10%;
                top: 53%;
                z-index: 1;

                -webkit-animation: bounce-up 3s linear infinite;
                animation: bounce-up 3s linear infinite;

                @media (max-width: 1639px) {
                    top: 46%;
                }

                @media (max-width: 1439px) {
                    top: 57%;
                }

                @media (max-width: 1023px) {
                    right: 2%;
                    top: 75%;
                }

                @media (max-width: 767px) {
                    top: 64%;
                    right: -1%;
                }
            }

            &.bp_tools4 {
                max-width: 38px;
                right: 12.3%;
                top: 35%;
                z-index: 1;

                -webkit-animation: bounce-down 7s linear infinite;
                animation: bounce-down 7s linear infinite;

                @media (max-width: 1639px) {
                    top: 29%;
                }

                @media (max-width: 1439px) {
                    top: 33%;
                }

                @media (max-width: 1023px) {
                    right: 5.3%;
                    top: 42%;
                }

                @media (max-width: 860px) {
                    right: 4%;
                }

                @media (max-width: 767px) {
                    top: 35%;
                    right: 33%;
                }

                @media (max-width: 700px) {
                    right: 30%;
                }

                @media (max-width: 600px) {
                    right: 27%;
                }

                @media (max-width: 529px) {
                    right: 24%;
                    top: 38%;
                }

                @media (max-width: 460px) {
                    right: 20%;
                }

                @media (max-width: 390px) {
                    right: 16%;
                }

                @media (max-width: 350px) {
                    right: 10%;
                }
            }

            &.bp_map {
                z-index: 0;
                max-width: 979px;
                width: 100%;
                right: 0;
                top: -107px;

                &:before {
                    // position: absolute;
                    background-image: url(../img/home/join/home_join_rectangle.png);
                    background-size: cover;
                    opacity: 0.2;
                    z-index: 100;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    // position: relative;
                    top: 0;
                    left: 0;
                    background: transparent;
                    background-image: url(../img/bookpay/hero/bookpay_hero_rectangle2.png),
                        linear-gradient(90deg, #131518 0%, rgba(19, 21, 24, 0) 86.35%),
                        linear-gradient(40deg, rgba(19, 21, 24, 0.5) 20.23%, rgba(247, 181, 0, 0) 84.84%),
                        linear-gradient(0deg, rgba(19, 21, 24, 0.5) 20.23%, rgba(247, 181, 0, 0.1) 84.84%),
                        linear-gradient(2deg, #131518 15.27%, rgba(19, 21, 24, 0.5) 100%);
                    // background: linear-gradient(90deg, #131518 0%, rgba(19, 21, 24, 0) 86.35%), linear-gradient(40deg, rgba(19, 21, 24, 0.5) 20.23%, rgba(247, 181, 0, 0) 84.84%), linear-gradient(0deg, rgba(19, 21, 24, 0.5) 20.23%, rgba(247, 181, 0, 0.1) 84.84%), linear-gradient(2deg, #131518 15.27%, rgba(19, 21, 24, 0.5) 100%);
                    // background: linear-gradient(77.02deg, #131518 0%, rgba(19, 21, 24, 0) 86.35%), linear-gradient(106.14deg, rgba(19, 21, 24, 0.5) 20.23%, rgba(247, 181, 0, 0) 84.84%), linear-gradient(142.68deg, #131518 15.27%, rgba(19, 21, 24, 0.5) 100%);
                }

                @media (max-width: 1023px) {
                    max-width: 834px;
                    top: -90px;
                }

                @media (max-width: 767px) {
                    top: -120px;
                }

                img {
                    opacity: 1;
                }
            }

            @-webkit-keyframes bounce-down {
                25% {
                    -webkit-transform: translateY(-4px);
                }

                50%,
                100% {
                    -webkit-transform: translateY(0);
                }

                75% {
                    -webkit-transform: translateY(4px);
                }
            }

            @keyframes bounce-down {
                25% {
                    transform: translateY(-4px);
                }

                50%,
                100% {
                    transform: translateY(0);
                }

                75% {
                    transform: translateY(4px);
                }
            }

            @-webkit-keyframes bounce-up {
                75% {
                    -webkit-transform: translateY(-10px);
                }

                50%,
                100% {
                    -webkit-transform: translateY(0);
                }

                25% {
                    -webkit-transform: translateY(10px);
                }
            }

            @keyframes bounce-up {
                75% {
                    transform: translateY(-10px);
                }

                50%,
                100% {
                    transform: translateY(0);
                }

                25% {
                    transform: translateY(10px);
                }
            }
        }
    }
}
