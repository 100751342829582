@import './_vars.scss';

.home_joinnow {
    position: relative;
    padding: 4.7rem 0 16.8rem;
    margin-left: 90px;
    overflow: hidden;

    @media (max-width: 1439px) {
        padding: 4.7rem 0 7rem;
        margin: 0 auto;
    }

    @media (max-width: 1280px) {
        padding: 4 0 12rem 0;
    }

    @media (max-width: 1023px) {
        // padding: 4.8rem 0 8rem;
    }

    @media (max-width: 767px) {
        padding: 0rem 0 4.8rem;
    }

    .cont {
        @media (max-width: 830px) {
            padding: 0 30px;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;

        @media (max-width: 1439px) {
            grid-gap: 45px 30px;
        }

        @media (max-width: 1260px) {
            grid-gap: 45px 10px;
        }

        @media (max-width: 1024px) {
            grid-gap: 30px 30px;
            margin-left: 0;
        }

        @media (max-width: 767px) {
            grid-gap: 61px 30px;
            grid-template-columns: repeat(1, 1fr);
        }

        .btn-download {
            @media (max-width: 1600px) {
                grid-template-columns: repeat(2, 180px);
            }

            @media (max-width: 1439px) {
                grid-template-columns: repeat(2, 207px);
            }

            @media (max-width: 1260px) {
                grid-template-columns: repeat(2, 150px);
            }

            @media (max-width: 1023px) {
                grid-template-columns: repeat(2, 150px);
            }

            @media (max-width: 830px) {
                grid-template-columns: repeat(1, 150px);
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(2, 150px);
            }
        }

        .item {
            .item-left {
                display: inline-block;
                padding-left: 25px;

                @media (max-width: 767px) {
                    padding: 0;
                }

                p {
                    color: $white;
                    padding: 17px 0 60px;
                    max-width: 448px;
                    font-size: 24px;
                    line-height: 140%;
                    font-weight: 400;

                    @media (max-width: 1639px) {
                        max-width: 374px;
                        font-size: 24px;
                        line-height: 30px;
                    }

                    @media (max-width: 1439px) {
                        font-size: 21px;
                        line-height: 26px;
                    }

                    @media (max-width: 1023px) {
                        font-size: 14px;
                        line-height: 18px;
                        max-width: 300px;
                        padding-bottom: 33px;
                    }

                    @media (max-width: 840px) {
                        padding: 18px 0;
                    }

                    @media (max-width: 767px) {
                        padding: 18px 0 25px;
                    }

                    // @media (max-width: 360px) {
                    //     padding: 18px 0 15px;
                    // }
                }

                .btn-download {
                    position: absolute;
                    top: 45%;

                    @media (max-width: 1439px) {
                        position: relative;
                        top: 53%;
                    }

                    @media (max-width: 1281px) {
                        top: 36%;
                    }

                    @media (max-width: 1023px) {
                        top: 45%;
                    }

                    @media (max-width: 830px) {
                        top: 38%;
                    }

                    @media (max-width: 767px) {
                        top: 18%;
                    }

                    @media (max-width: 359px) {
                        grid-template-columns: repeat(1, 1fr);
                        top: 15%;
                    }

                    .btn.appstore {
                        max-width: 206px;
                        padding: 14px 30px 12.04px 30.18px;

                        img {
                            max-width: 100%;
                            width: 145.82px !important;
                        }

                        @media (max-width: 1023px) {
                            max-width: 150px;
                            padding: 14px 21px 12.63px 20px;

                            img {
                                max-width: 100%;
                                width: 109px !important;
                            }
                        }
                    }

                    .btn.googleplay {
                        max-width: 206px;
                        padding: 15px 25.54px 12.98px 25px;
                        background: $yellow !important;

                        img {
                            max-width: 155.46px !important;
                        }

                        @media (max-width: 1023px) {
                            max-width: 150px;
                            padding: 14.45px 15px 13.45px 18px;

                            img {
                                max-width: 117px !important;
                            }
                        }
                    }
                }
            }

            .item-right {
                background-image: url(../img/home/join/bg_join.png);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 15px;
                display: inline-block;

                @media (max-width: 1439px) {
                    padding: 35px 30px 125px 30px;
                }

                @media (max-width: 1023px) {
                    padding: 22px 20px 34px 29px;
                }

                @media (max-width: 830px) {
                    padding: 31px 20px 44px;
                }

                @media (max-width: 360px) {
                    padding: 20px 10px 40px;
                }

                .bg {
                    padding: 42px 48px 40px 48px;
                    position: relative;
                    z-index: 2;

                    @media (max-width: 1439px) {
                        padding: 0;
                    }
                }

                .bg:before {
                    content: ' ';
                    display: block;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(../img/home/join/home_join_rectangle.png);
                    background-repeat: no-repeat;
                    background-size: cover;
                    opacity: 0.2;
                    position: absolute;
                    z-index: 1;
                    background-position: 50% 0;
                }

                .logo-section {
                    display: inline-flex;
                    text-align: center;
                    align-items: center;

                    @media (max-width: 359px) {
                        display: grid;
                        text-align: start;
                    }

                    h2 {
                        font-weight: 800;
                        font-size: 38px;
                        line-height: 45px;

                        @media (max-width: 1439px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }

                    .home_join_tr {
                        display: inline-flex;

                        @media (max-width: 1515px) {
                            display: block;
                            text-align: start;
                            align-items: flex-start;
                        }
                    }

                    .home_join_tr-item1 {
                        display: inline-flex;
                    }

                    .small_tr-img {
                        max-width: 71px;
                        vertical-align: middle;
                        padding-bottom: 2px;
                        padding-left: 0;

                        @media (max-width: 1023px) {
                            max-width: 61px;
                            padding: 4px 4px 0 0;
                        }
                    }

                    .small_tr {
                        font-weight: 800;
                        font-size: 26px;
                        line-height: 31px;
                        text-transform: capitalize;

                        @media (max-width: 1023px) {
                            font-size: 24px;
                            line-height: 29px;
                        }
                    }

                    img {
                        vertical-align: middle;
                        max-width: 103px;
                        padding: 6px 10px 0;

                        @media (max-width: 1439px) {
                            max-width: 63px;
                            padding: 5px 8px 0;
                        }
                    }
                }

                p {
                    color: $white;
                    padding: 24px 0 39px;
                    max-width: 448px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 29px;
                    letter-spacing: 0.02em;

                    @media (max-width: 1639px) {
                        max-width: 374px;
                        font-size: 17px;
                        line-height: 26px;
                    }

                    @media (max-width: 1023px) {
                        max-width: 400px;
                        font-size: 14px;
                        line-height: 18px;
                        padding: 16px 0 24px;
                    }

                    @media (max-width: 840px) {
                        padding: 18px 0;
                    }

                    @media (max-width: 767px) {
                        padding: 18px 0 45px;
                    }
                }

                .btn-download {
                    position: relative;
                    top: 45%;
                    z-index: 5;

                    @media (max-width: 1439px) {
                        top: 53%;
                    }

                    @media (max-width: 1281px) {
                        top: 36%;
                    }

                    @media (max-width: 1023px) {
                        top: 43%;
                    }

                    @media (max-width: 830px) {
                        top: 36%;
                    }

                    @media (max-width: 767px) {
                        top: 65%;
                    }

                    @media (max-width: 390px) {
                        top: 70%;
                        grid-gap: 25px 5px;
                    }

                    @media (max-width: 359px) {
                        grid-template-columns: repeat(1, 1fr);
                        top: 66%;
                    }

                    .btn {
                        cursor: pointer;
                    }

                    .btn.appstore_white,
                    .btn.googleplay_white {
                        background: $primary_black;
                    }

                    .btn.appstore_white {
                        padding: 14px 30.18px 12.04px 30px;

                        img {
                            max-width: 145px;
                        }

                        @media (max-width: 1023px) {
                            padding: 12.25px 18.38px 11.05px 17.5px;

                            img {
                                max-width: 95.37px;
                            }
                        }
                    }

                    .btn.googleplay_white {
                        padding: 15px 25.54px 12.98px 25px;

                        img {
                            max-width: 155px;
                        }

                        @media (max-width: 1023px) {
                            padding: 12.67px 13.15px 11.79px 15.79px;

                            img {
                                max-width: 102.6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
