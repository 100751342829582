@import './_vars.scss';

@mixin customStyle($cursorType, $borderColor: #f7b500) {
    border: 2px solid $borderColor;
    border-radius: 15px;
    cursor: $cursorType;
    // padding: 15px 18px;
}

.auto_service_partner {
    margin: 0;
    margin-left: 200px;
    margin-bottom: 127px;
    position: relative;
    max-width: 650px;

    .hidden {
        display: none;
    }

    h2 {
        margin-top: 80px;
        margin-bottom: 20px;
    }

    h6 {
        margin-top: 15px;
        text-transform: none;
    }

    p {
        font-size: 24px;
        line-height: 1.3;
        width: 500px;
    }

    @media (max-width: 1439px) {
        margin-left: 100px;
    }

    @media (max-width: 1280px) {
        margin-left: 80px;
    }

    @media (max-width: 1080px) {
        margin-left: 60px;
    }

    @media (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        max-width: 100%;
        padding: 0 70px;

        h2 {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
        }

        p {
            font-size: 14px;
            width: 500px;
        }

        .service_form {
            margin: 48px auto 0;

            #contact-form {
                max-width: 100%;

                .form-group {
                    font-size: 14px;
                    line-height: 19px;
                }

                textarea {
                    line-height: 1.3;
                }

                .form-control {
                    padding: 16px 15px 17px;
                    max-width: 440px;
                }
            }
        }

        .hidden {
            margin-top: 40px;
            display: block;
        }
    }

    @media (max-width: 767px) {
        padding: 0 20px;
        .title {
            width: 90%;
        }

        .service_form {
            #contact-form {
                width: 100%;
            }
        }

        h2 {
            margin-top: 50px;
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;
            width: 300px;
        }
    }

    .service_form {
        display: flex;
        flex-direction: column;
        margin: 21px auto 0;
        z-index: 3;

        #contact-form {
            max-width: 400px;

            input,
            textarea,
            select {
                z-index: 3;
                font-size: 18px;
                border: 2px solid #2a3038;
                border-radius: 15px;
                transition: all 0.3s ease;
                &:hover,
                &:focus {
                    @include customStyle(pointer);
                }
                &:focus {
                    @include customStyle(text);
                }
            }

            button {
                z-index: 3;
                font-size: 18px;
                border: 2px solid #2a3038;
                border-radius: 15px;
                transition: all 0.3s ease;
                &:hover,
                &:focus {
                    @include customStyle(pointer);
                }
                &:focus {
                    @include customStyle(pointer);
                }
            }

            .form-group {
                color: $white;
                font-family: 'SFProDisplay', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                display: grid;
                margin-bottom: 24px;
                position: relative;
                .form-label {
                    color: $grey;
                    margin-bottom: 16px;
                }
            }

            .form-control {
                background: #ffffff26;
                border-radius: 15px;
                color: $white;
                padding: 16px 19px;

                &:focus-visible {
                    outline-style: auto;
                    outline-width: 0;
                }
            }

            .input_error {
                color: #ff1414;
                font-size: 12px;
                line-height: 14px;
                position: absolute;
                bottom: -20px;
                right: 10px;
            }

            .form-control.input-error {
                @include customStyle(auto, #ff1414);
            }

            .image-plus {
                font-family: 'SFProDisplay', sans-serif;
                color: $grey;
                font-size: 18px;
                padding: 15px;
            }
            .image-plus.error {
                color: #ff1414;
            }
        }
    }
}

.auto_service_img {
    position: absolute;
    z-index: 1;
    max-width: 500px;
    right: 100px;
    top: 190px;
    z-index: 7;

    @media (max-width: 1439px) {
        right: 100px;
        top: 190px;
    }

    @media (max-width: 1280px) {
        right: 5%;
    }

    @media (max-width: 1080px) {
        right: 0;
    }

    @media (max-width: 1023px) {
        position: static;
        display: none;
    }
}
