@import './_vars.scss';

.bookpay_faq {
    position: relative;
    padding: 9.24rem 0 10.95rem;

    @media (max-width: 1537px) {
        padding: 9.24rem 0 6.3rem;
    }

    @media (max-width: 1439px) {
        padding: 9.4rem 0 7.1rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 2rem;
    }

    @media (max-width: 1023px) {
        padding: 6.7rem 0 7.2rem;
    }

    @media (max-width: 767px) {
        padding: 5.1rem 0 8rem;
    }

    @media (max-width: 479px) {
        padding: 4.5rem 0;
    }

    h2 {
        @media (max-width: 1439px) {
            padding: 0 0 76px 70px;
        }

        @media (max-width: 1023px) {
            padding: 5px 0 53px;
        }
    }

    .accordions .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
        padding: 40px 84px 37px;

        @media (max-width: 1439px) {
            padding: 38px 70px 36px 68px;
        }

        @media (max-width: 1023px) {
            padding: 9px 19px 19px 29px;
        }
    }

    .accordions .css-15v22id-MuiAccordionDetails-root {
        @media (max-width: 1023px) {
            padding: 0 30px 30px;
            margin-top: -26px;
        }
    }

}