@import './_vars.scss';

.bookpay_benefits {
  position: relative;
  padding: 10.1rem 0rem 11rem;

  @media (max-width: 1537px) {
    padding: 5.7rem 0 14.7rem;
  }

  @media (max-width: 1439px) {
    padding: 9rem 0 7rem;
  }

  @media (max-width: 1280px) {
    padding: 8.8rem 0;
  }

  @media (max-width: 1023px) {
    padding: 4.7rem 0;
    background-size: 1320px 480px;
  }

  @media (max-width: 767px) {
    padding: 5.1rem 0 2rem;
    background-size: 1320px 670px;
  }

  @media (max-width: 479px) {
    padding: 3rem 0;
  }

  .background-line {
    position: absolute;
    top: 45px;
    height: 90%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 0;

    &.desktop-t {
      display: none;
    }

    &.desktop-m {
      display: none;
    }

    @media (max-width: 1439px) {
      top: 20px;
    }

    @media (max-width: 1023px) {
      &.desktop-d {
        display: none;
      }

      &.desktop-t {
        display: block;
      }
    }

    @media (max-width: 767px) {
      &.desktop-t {
        display: none;
      }

      &.desktop-m {
        display: block;
      }
    }
  }

  .path {
    stroke-dasharray: 5;
    -webkit-animation: dash 40s linear;
    animation: dash 40s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  :not(svg) {
    transform-origin: 0px 0px;
  }

  @keyframes dash {
    100% {
      stroke-dashoffset: 1000;
    }
  }

  .benefits {

    .background-line {
      position: absolute;
      top: 275px;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 0;

      @media (max-width: 1639px) {
        top: 200px;
      }

      @media (max-width: 1279px) {
        top: 90px;
      }

      @media (max-width: 1279px) {
        top: -100px;
      }

      @media (max-width: 767px) {
        top: 75px;
      }
    }

    .path {
      stroke-dasharray: 5;
      -webkit-animation: dash 40s linear;
      animation: dash 40s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    :not(svg) {
      transform-origin: 0px 0px;
    }

    @keyframes dash {
      100% {
        stroke-dashoffset: 1000;
      }
    }

    h2 {
      @media (max-width: 1439px) {
        margin-top: -4px;
        font-size: 3.1rem;
      }

      @media (max-width: 1023px) {
        margin-top: 0;
        font-size: 30px;
        line-height: 36px;
      }
    }

    .grid {
      padding-right: 72px;
      background-color: $primary_black;
      z-index: 3;

      &:before {
        position: absolute;
        content: ' ';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../img/bookpay/benifits/bookpay_benefits_rectangle.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 0;
        opacity: 0.2;
        z-index: 1;
      }

      @media (max-width: 1485px) {
        padding-right: 30px;
      }

      @media (max-width: 1439px) {
        grid-gap: 45px 80px;
      }

      @media (max-width: 1400px) {
        margin-right: 0;
      }

      @media (max-width: 1023px) {
        margin-top: 51px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px 5px;
      }

      @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px 30px;
      }

      .item {
        .unodered-list {
          max-width: 290px;

          #item-text {
            @media (max-width: 1439px) {
              font-size: 18px;
              line-height: 21px;
            }

            @media (max-width: 1140px) {
              font-size: 16px;
            }

            @media (max-width: 1023px) {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}