@import './_vars.scss';

.insurance_benefits {
  position: relative;
  padding: 11.75rem 0rem 7rem;

  @media (max-width: 1537px) {
    padding: 7.7rem 0 8rem;
  }

  @media (max-width: 1439px) {
    padding: 9rem 0 5rem;
  }

  @media (max-width: 1280px) {
    padding-top: 8.8rem;
  }

  @media (max-width: 1023px) {
    padding-top: 4.9rem;
  }

  @media (max-width: 767px) {
    padding: 0.1rem 0 2rem;
  }

  .background-line {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 0;

    &.desktop-t {
      display: none;
    }

    &.desktop-m {
      display: none;
    }

    @media (max-width: 1023px) {
      &.desktop-d {
        display: none;
      }

      &.desktop-t {
        display: block;
        top: 0px
      }
    }

    @media (max-width: 767px) {
      &.desktop-t {
        display: none;
      }

      &.desktop-m {
        display: block;
        top: 450px
      }
    }
  }

  .path {
    stroke-dasharray: 5;
    -webkit-animation: dash 40s linear;
    animation: dash 40s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  :not(svg) {
    transform-origin: 0px 0px;
  }

  @keyframes dash {
    100% {
      stroke-dashoffset: 1000;
    }
  }

  .grid {
    background-color: $primary_black;
    z-index: 3;

    &:before {
      position: absolute;
      content: ' ';
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(../img/insurance/benefits/insurance_benefits_rectangle.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 0;
      opacity: 0.2;
      z-index: 1;
    }

    @media (max-width: 1023px) {
      margin-top: 42px;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 35px 5px;
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px 30px;
    }
  }
}