@import './_vars.scss';

.footer {
    position: relative;

    &:before {
        content: ' ';
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(255, 255, 255, 0.05);
        opacity: 0.5;
    }

    .top {
        position: relative;

        .grid {
            padding: 48px 119px 56px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 45px 30px;
            justify-content: space-between;
            text-align: start;

            @media (max-width: 1439px) {
                padding: 51px 60px 40px 70px;
            }

            @media (max-width: 1023px) {
                padding: 51px 70px 38px;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 26px 30px;
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 30px 30px;
                padding: 41px 20px 28px;
            }

            .item {
                padding: 0;

                &.first {
                    .footer-logo {
                        max-width: 85px;
                        padding: 13px 0 22px;

                        @media (max-width: 1439px) {
                            padding: 0 0 22px;
                        }

                        @media (max-width: 1023px) {
                            padding: 9px 0 22px;
                        }
                    }

                    .footer-sub {
                        max-width: 320px;

                        @media (max-width: 1439px) {
                            max-width: 275px;
                        }

                        @media (max-width: 1023px) {
                            max-width: 100%;
                        }

                        p {
                            font-size: 14px;
                            line-height: 21px;

                            @media (max-width: 1439px) {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }

                .footer-title {
                    @include font-size(1.438, 1.174);
                    font-weight: 800;
                    color: $white;
                    padding: 18px 0 23px;

                    @media (max-width: 1439px) {
                        @include font-size(1.125, 1.167);
                        padding: 9px 0 28px;
                    }

                    @media (max-width: 1023px) {
                        padding-bottom: 20px;
                    }
                }

                li {
                    @include font-size(0.875, 1.167);
                    color: $white;
                    padding: 0 0 16px;

                    @media (max-width: 1023px) {
                        padding-bottom: 15px;
                    }
                }

                &.third,
                &.second {
                    justify-self: center;

                    @media (max-width: 1023px) {
                        justify-self: start;
                    }
                }

                &:last-child {
                    justify-self: end;
                    padding-right: 10px;

                    @media (max-width: 1023px) {
                        grid-column: 2;
                        grid-row: 1;
                        justify-self: end;
                    }

                    @media (max-width: 767px) {
                        grid-column: 1;
                        grid-row: 4;
                        justify-self: center;

                        .mail {
                            text-align: center;
                        }
                    }
                }

                &.second {
                    padding-left: 7px;

                    @media (max-width: 1023px) {
                        padding: 11px 0 0;
                    }

                    @media (max-width: 767px) {
                        padding-left: 0;
                    }
                }

                &.third {
                    padding-right: 59px;

                    @media (max-width: 1439px) {
                        padding: 0;
                    }

                    @media (max-width: 1023px) {
                        padding: 11px 0 0;
                    }

                    @media (max-width: 767px) {
                        padding: 11px 0;
                    }
                }

                &.fourth {
                    .socialmedia {
                        @media (max-width: 1023px) {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }

    .bottom {
        border-top: 1px solid #2A3038;
        text-align: center;
        position: relative;
        z-index: 1;

        padding: 19px;

        @media (max-width: 1439px) {
            padding: 9px;
        }

        @media (max-width: 767px) {
            padding: 1.2rem 0 1.5em;
        }

        p {

            color: $grey;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            text-transform: capitalize;

            @media (max-width: 1439px) {
                font-size: 0.75rem;
            }
        }
    }
}