@import './_vars.scss';

.home_bg-drops2 {
    background-image: url(../img/home/bgrnd_drops2.png);
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;
    background-size: 527px 634px;
}

.home_team {
    position: relative;
    padding: 8.21rem 0 7.9rem;
    background-image: url(../img/home/team/bg_team.png);
    background-repeat: no-repeat;
    background-size: 520px 596px;
    background-position: left;
    background-position-y: center;

    @media (max-width: 1439px) {
        padding: 6.7rem 0 4.6rem;
    }

    @media (max-width: 1280px) {
        padding: 3.54rem 0 4.6rem;
    }

    @media (max-width: 1023px) {
        padding: 7.1rem 0 5.6rem;
        background-size: 220px 830px;
    }

    @media (max-width: 767px) {
        padding: 7.9rem 0 5.2rem;
        background-size: 200px 645px;
    }

    .home_team-container {
        padding-left: 100px;

        @media (max-width: 1023px) {
            padding: 0;
        }
    }

    h2 {
        margin-top: -8px;
        line-height: 69px;
        letter-spacing: -0.01em;

        @media (max-width: 1439px) {
            margin-top: -5px;
        }

        @media (max-width: 1023px) {
            margin-top: 4px;
            font-size: 30px;
            line-height: 36px;
        }

        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 33px;
        }
    }

    .home_team_subtitle {
        max-width: 970px;
        font-weight: 400;
        font-size: 24px;
        line-height: 31px;
        margin: 19px 0 0;

        @media (max-width: 1639px) {
            font-size: 21px;
            line-height: 28px;
        }

        @media (max-width: 1023px) {
            margin-top: 16px;
            font-size: 18px;
            line-height: 23px;
        }

        @media (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .background-line {
        position: absolute;
        bottom: 0%;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;

        &.desktop-t {
            display: none;
        }

        &.desktop-m {
            display: none;
        }

        @media (max-width: 1023px) {
            &.desktop-d {
                display: none;
            }

            &.desktop-t {
                display: block;
                top: 200px;
            }
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }

            &.desktop-m {
                display: block;
                top: 220px;
            }
        }
    }

    .path {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    :not(svg) {
        transform-origin: 0px 0px;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }

    .slide-team1 {
        padding: 129px 0 5px 0;

        @media (max-width: 1639px) {
            padding: 100px 0 35px 0;
        }

        @media (max-width: 1439px) {
            padding: 80px 0 35px 0;
        }

        @media (max-width: 767px) {
            padding: 0;
            margin: 72px 0 0;
        }

        h6 {
            margin-top: 10px;
        }

        .slick-list {
            min-width: 1700px;

            @media (max-width: 767px) {
                height: 270px !important;
            }
            @media (max-width: 441px) {
                height: 200px !important;
            }
        }

        .slick-track {
            margin-left: 60px;
        }
    }
}
