@import './_vars.scss';

.parking_back_slider-join {
    background-image: url(../img/parking/parking_bgrnd_slider.png);
    background-size: 364px 520px;
    background-repeat: no-repeat;
    background-position-y: 30%;

    @media (max-width: 767px) {
        background: none;
    }
}

.parking_back_join-faq {
    background-image: url(../img/parking/parking_bgrnd_join.png);
    background-size: 260px 519px;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: 30%;

    @media (max-width: 767px) {
        background: none;
    }
}

.parking_joinnow {
    position: relative;
    padding: 9.6rem 0 6.1em;
    padding-left: 90px;

    @media (max-width: 1537px) {
        padding: 9.5rem 0 7rem;
    }

    @media (max-width: 1439px) {
        padding: 7rem 0 7rem;
        margin: 0 auto;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 4.12rem 0 5.55rem;
        background-size: 450px 520px;
    }

    @media (max-width: 767px) {
        padding: 4rem 0 4rem;
    }

    .item-top {
        h2 {
            @media (max-width: 1439px) {
                padding-top: 6px;
            }

            @media (max-width: 1023px) {
                padding-top: 9px;
            }
        }

        p {
            max-width: 521px !important;
            font-size: 24px !important;
            line-height: 127.5% !important;

            @media (max-width: 1439px) {
                font-size: 20px !important;
            }

            @media (max-width: 1023px) {
                font-size: 14px !important;
                line-height: 127.5%v !important;
                padding: 18px 0 43px;
            }

            @media (max-width: 830px) {
                padding: 18px 0 !important;
            }

            @media (max-width: 767px) {
                width: 100% !important;
            }
        }
    }

    .item-counters {
        z-index: 3;

        &:before {
            position: absolute;
            content: ' ';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/parking/join/parking_join_rectangle.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            opacity: 0.2;
            z-index: 1;
        }

        @media (max-width: 1681px) {
            padding-right: 10px !important;
        }

        @media (max-width: 1441px) {
            padding: 156px 10px 33px 38px !important;
        }

        @media (max-width: 1290px) {
            padding: 171px 10px 33px 18px !important;
        }

        @media (max-width: 1023px) {
            padding: 114px 5px 15px 18px !important;
        }

        @media (max-width: 767px) {
            padding: 127px 5px 15px 18px !important;
        }
    }

    .item-counters_title {
        font-size: 37px !important;
        line-height: 44px !important;
        text-transform: capitalize !important;

        @media (max-width: 1023px) {
            font-size: 1.875rem !important;
        }

        @media (max-width: 767px) {
            font-size: 24px !important;
            line-height: 29px !important;
        }
    }

    .item-counters_subtitle {
        font-size: 18px !important;

        @media (max-width: 1365px) {
            font-size: 16px !important;
            line-height: 19px !important;
        }

        @media (max-width: 1023px) {
            font-size: 14px !important;
            line-height: 17px !important;
        }
    }

    #joinnow-logo {
        @media (max-width: 767px) {
            max-width: 34px !important;
        }
    }

    /* identical to box height */

    letter-spacing: 0.02em;

    .item {

        &.trusted,
        &.secure {
            @media (max-width: 1290px) {
                padding-left: 18px !important;
            }

            @media (max-width: 1023px) {
                padding-left: 18px !important;
            }
        }

        &.trusted {
            @media (max-width: 1023px) {
                padding-top: 20px !important;
            }
        }
    }

    .item-trusted_title,
    .item-secure_title {
        font-size: 26px !important;
        line-height: 31px !important;

        @media (max-width: 1023px) {
            font-size: 16px !important;
            line-height: 19px !important;
        }
    }

    .item-counters-bottom {
        @media (max-width: 1023px) {
            padding-top: 1px !important;
        }
    }

    .item-trusted_subtitle,
    .item-secure_subtitle {
        font-size: 18px !important;
        line-height: 29px !important;

        @media (max-width: 1023px) {
            font-size: 14px !important;
            line-height: 29px !important;
        }
    }
}