@import './_vars.scss';

.parking_feedback {
    position: relative;
    padding: 9.24rem 0 7.95rem;

    @media (max-width: 1439px) {
        padding: 9.4rem 0 7.1rem;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 2rem;
    }

    @media (max-width: 1023px) {
        padding: 8.6rem 0 5.2rem;
    }

    @media (max-width: 767px) {
        padding: 3.9rem 0 2.05rem;
    }

    h2 {
        @media (max-width: 1439px) {
            padding-left: 70px;
        }

        @media (max-width: 1023px) {
            padding-left: 0px;
        }
    }

    p {
        padding: 24px 0 0 89px;

        @media (max-width: 1439px) {
            padding: 20px 0 76px 70px;
        }

        @media (max-width: 1023px) {
            padding: 15px 0 0;
        }
    }

    .parking_form {
        max-width: 930px;
        border-radius: 15px;
        margin: 121px auto 0;
        padding: 60px 70px 70px;
        background-image: url(../img/parking/feedback/feedback-background.png);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 3;

        &:before {
            position: absolute;
            content: ' ';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/parking/feedback/parking_feedback_rectangle.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            opacity: 0.2;
            z-index: 1;
        }

        @media (max-width: 1023px) {
            margin: 44px 20px;
            padding: 30px 30px 40px;
            max-width: 883px;
        }

        @media (max-width: 767px) {
            margin: 44px 20px;
            padding: 30px;
        }

        #contact-form {
            display: grid;
            grid-template-columns: repeat(1, 260px 586px);
            grid-gap: 42px 40px;
            justify-content: start;

            @media (max-width: 1023px) {
                grid-gap: 15px 15px;
                grid-template-columns: repeat(1, 1fr 1.5fr);
            }

            @media (max-width: 767px) {
                grid-gap: 15px 15px;
                grid-template-columns: repeat(1, 1fr);
            }

            input,
            textarea {
                z-index: 3;
            }

            .form-column:nth-child(1) {
                max-width: 260px;

                @media (max-width: 1023px) {
                    max-width: 240px;
                }
            }

            .form-group {
                color: $white;
                font-family: "SFProDisplay", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                display: grid;

                @media (max-width: 1023px) {
                    font-size: 14px;
                    line-height: 19px;
                }

                .form-label {
                    color: $grey;
                    margin-bottom: 16px;
                    max-width: 260px;

                    @media (max-width: 1023px) {
                        max-width: 240px;
                    }

                    &.email {
                        margin-top: 24px;
                        max-width: 260px;
                    }
                }

                textarea {
                    @media (max-width: 1023px) {
                        line-height: 1.3;
                    }
                }
            }

            .form-control {
                background: #FFFFFF26;
                border: 1px solid #191D22;
                border-radius: 15px;
                color: $white;
                padding: 16px 20px 17px;

                @media (max-width: 1023px) {
                    padding: 16px 15px 17px;
                }

                &.name,
                &.email {
                    max-width: 260px;

                    @media (max-width: 1023px) {
                        max-width: 240px;
                    }

                    @media (max-width: 767px) {
                        max-width: 270px;
                    }
                }

                &.message {
                    max-width: 630px;
                    z-index: 1;
                }
            }

            .form-control:focus-visible {
                outline-style: auto;
                outline-width: 0;
            }

            input:focus {
                border: 1px solid #191D22;
            }

            .input_error {
                color: #F7B500;
                font-size: 12px;
                line-height: 14px;
            }

            .form-btn {
                overflow: auto;
                position: relative;
                float: right;
                margin-top: -84px;
                margin-right: 16px;
                padding: 20px;
                background-color: #FFFFFF26;
                border: none;
                border-radius: 15px;
                z-index: 2;
                cursor: pointer;

                @media (max-width: 1023px) {
                    margin-top: -70px;
                    padding: 18px;
                }

                &:hover {
                    background-color: $yellow !important;
                    cursor: pointer;

                    .form-btn_img {
                        filter: brightness(150%) !important;
                    }
                }

                &:active {
                    background-color: $yellow;
                }

                img {
                    max-width: 24px;
                    z-index: 101;

                    @media (max-width: 1023px) {
                        max-width: 20px;
                    }
                }
            }

            @keyframes swirl-in-fwd {
                0% {
                    transform: rotate(-540deg) scale(0);
                    opacity: 0;
                }

                100% {
                    transform: rotate(0) scale(1);
                    opacity: 1;
                }
            }

            .swirl-in-fwd {
                animation: swirl-in-fwd 0.6s ease-out both;
            }
        }
    }
}