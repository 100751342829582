@import './_vars.scss';

section .home_hero {
    padding-top: 0px;
}

.home_hero {
    padding: 0;
    position: relative;
    overflow: visible;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @media (max-width: 1439px) {
        height: auto;
    }

    .cont {
        margin: 0;

        @media (max-width: 767px) {
            width: 100%;
            margin: 0 auto;
            padding: 0 20px;
        }
    }

    .content-wrap {
        position: relative;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: start;
        max-width: 731px;
        box-sizing: border-box;
        text-align: start;
        float: left;
        padding: 169px 0 557px;
        margin-left: 90px;

        @media (max-width: 1800px) {
            margin-left: 0;
        }

        @media (max-width: 1639px) {
            padding-bottom: 450px;
        }

        @media (max-width: 1537px) {
            padding-bottom: 425px;
        }

        @media (max-width: 1439px) {
            padding: 171px 0 400px;
            max-width: 720px;
        }

        @media (max-width: 1279px) {
            padding-top: 100px;
            max-width: 555px;
        }

        @media (max-width: 1023px) {
            max-width: 363px;
            padding: 68px 0 274px;
        }

        @media (max-width: 767px) {
            max-width: 335px;
            padding: 48px 0 610px;
            margin: 0 auto;
        }

        @media (max-width: 359px) {
            max-width: 280px;
        }

        .heroZoomIn {
            @media (max-width: 1700px) {
                max-width: 97%;
            }

            @media (max-width: 1439px) {
                max-width: 91%;
            }

            @media (max-width: 1023px) {
                max-width: 85%;
            }

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }

        .heroZoomIn.yellow {
            color: $yellow;
            margin: 0;
            text-shadow: 8px 5px 0px rgba(0, 0, 0, 100%);

            @media (max-width: 1279px) {
                text-shadow: 5px 3px 0px rgba(0, 0, 0, 100%);
            }

            @media (max-width: 1023px) {
                text-shadow: 4px 3px 0px rgba(0, 0, 0, 25%);
            }
        }

        h1 {
            padding-bottom: 0;
            margin: 0;
            font-size: 64px;
            text-shadow: 8px 5px 0px rgba(0, 0, 0, 100%);
            line-height: 76px;

            @media (max-width: 1700px) {
                font-size: 58px;
                line-height: 70px;
            }

            @media (max-width: 1439px) {
                font-size: 54px;
                line-height: 68px;
            }

            @media (max-width: 1279px) {
                font-size: 42px;
                line-height: 50px;
                text-shadow: 5px 3px 0px rgba(0, 0, 0, 100%);
            }

            @media (max-width: 1023px) {
                font-size: 26px;
                line-height: 31px;
                letter-spacing: -0.01em;
            }

            @media (max-width: 767px) {
                font-size: 1.875rem;
                text-align: start;
            }

            span {
                position: relative;
                white-space: nowrap;
            }
        }

        .home-subtitle {
            width: 620px;
            margin-top: 37px;

            @media (max-width: 1279px) {
                max-width: 500px;
            }

            @media (max-width: 1023px) {
                margin-top: 18px;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.03em;
                max-width: 100%;
            }

            @media (max-width: 767px) {
                max-width: 100%;
            }
        }

        .home-logo {
            max-width: 52.45px;
            display: inline;
            vertical-align: middle;

            @media (max-width: 1023px) {
                max-width: 40px;
                padding-right: 2px;
            }
        }

        .btn-download {
            position: absolute;
            top: 50%;

            @media (max-width: 1639px) {
                top: 55%;
            }

            @media (max-width: 1439px) {
                top: 60%;
            }

            @media (max-width: 1279px) {
                top: 55%;
            }

            @media (max-width: 1023px) {
                top: 52%;
            }

            @media (max-width: 767px) {
                top: 33%;
            }

            @media (max-width: 359px) {
                top: 35%;
                grid-template-columns: repeat(1, 1fr);
            }

            .btn.appstore {
                max-width: 206px;
                padding: 14px 30px 12.04px 30.18px;

                img {
                    max-width: 100%;
                    width: 145.82px !important;
                }

                @media (max-width: 767px) {
                    max-width: 150px;
                    padding: 14px 21px 12.63px 20px;

                    img {
                        max-width: 100%;
                        width: 109px !important;
                    }
                }
            }

            .btn.googleplay {
                max-width: 206px;
                padding: 15px 25.54px 12.98px 25px;

                img {
                    max-width: 155.46px !important;
                }

                @media (max-width: 767px) {
                    max-width: 150px;
                    padding: 14.45px 15px 13.45px 18px;

                    img {
                        max-width: 117px !important;
                    }
                }
            }
        }
    }

    .background-line {
        position: absolute;
        top: 275px;
        width: 100%;
        left: 0;
        right: 0;
        z-index: 0;

        &.desktop-t {
            display: none;
        }

        &.desktop-m {
            display: none;
        }

        @media (max-width: 1639px) {
            top: 200px;
        }

        @media (max-width: 1279px) {
            top: 90px;
        }

        @media (max-width: 1023px) {
            &.desktop-d {
                display: none;
            }

            &.desktop-t {
                display: block;
                top: 160px;
            }
        }

        @media (max-width: 767px) {
            &.desktop-t {
                display: none;
            }

            &.desktop-m {
                display: block;
                top: 450px;
            }
        }
    }

    .path {
        stroke-dasharray: 5;
        -webkit-animation: dash 40s linear;
        animation: dash 40s linear;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

    :not(svg) {
        transform-origin: 0px 0px;
    }

    @keyframes dash {
        100% {
            stroke-dashoffset: 1000;
        }
    }

    .home_back {
        .home_img-wrap {
            position: absolute;
            z-index: 1;

            &.home_mobile1 {
                position: absolute;
                right: 14.5%;
                top: 217px;
                z-index: 2;
                -webkit-animation: bounce-down 5s linear infinite;
                animation: bounce-down 5s linear infinite;

                @media (max-width: 1639px) {
                    width: 25%;
                }

                @media (max-width: 1439px) {
                    top: 190px;
                    right: 10%;
                }

                @media (max-width: 1023px) {
                    max-width: 240px;
                    width: 100%;
                    right: 20%;
                    top: 100px;
                    z-index: 1;
                }

                @media (max-width: 950px) {
                    right: 15%;
                }

                @media (max-width: 767px) {
                    max-width: 213px;
                    width: 100%;
                    margin: 0 auto;
                    top: 57%;
                    right: 40%;
                }

                @media (max-width: 359px) {
                    right: 30%;
                }

                img {
                    width: 449px;
                    max-width: 78%;

                    @media (max-width: 1023px) {
                        max-width: 240px;
                        width: 100%;
                    }

                    @media (max-width: 950px) {
                        max-width: 240px;
                        width: 90%;
                    }

                    @media (max-width: 767px) {
                        max-width: 213px;
                        width: 100%;
                    }
                }
            }

            &.home_mobile2 {
                right: 3%;
                top: 89px;
                z-index: 1;
                -webkit-animation: bounce-up 5s linear infinite;
                animation: bounce-up 5s linear infinite;

                @media (max-width: 1639px) {
                    width: 25%;
                }

                @media (max-width: 1439px) {
                    right: 1%;
                }

                @media (max-width: 1023px) {
                    max-width: 240px;
                    width: 100%;
                    right: 5%;
                    top: 31px;
                    z-index: 2;
                }

                @media (max-width: 950px) {
                    right: 2%;
                }

                @media (max-width: 767px) {
                    max-width: 213px;
                    width: 100%;
                    margin: 0 auto;
                    top: 46%;
                    left: 30%;
                    right: 0;
                }

                img {
                    width: 449px;
                    max-width: 78%;

                    @media (max-width: 1023px) {
                        max-width: 240px;
                        width: 100%;
                    }

                    @media (max-width: 950px) {
                        width: 90%;
                    }

                    @media (max-width: 767px) {
                        max-width: 213px;
                        width: 100%;
                    }
                }
            }

            &.home_qr {
                max-width: 164px;
                right: 27%;
                top: 550px;
                z-index: 3;
                border-radius: 30px;

                @media (max-width: 1639px) {
                    width: 10%;
                    top: 490px;
                }

                @media (max-width: 1439px) {
                    top: 400px;
                    right: 23%;
                }

                @media (max-width: 1279px) {
                    top: 370px;
                }

                @media (max-width: 1023px) {
                    max-width: 88.36px;
                    width: 100%;
                    right: 30%;
                    top: 340px;
                }

                @media (max-width: 950px) {
                    width: 90%;
                    right: 28%;
                    top: 310px;
                }

                @media (max-width: 767px) {
                    margin: 0 auto;
                    top: 82%;
                    left: 0;
                    right: 25%;
                }

                @media (max-width: 460px) {
                    right: 55%;
                }
            }

            &.home_hero_bg {
                z-index: 0;
                max-width: 596px;
                width: 100%;
                left: 0;
                top: -115px;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                @media (max-width: 1023px) {
                    max-width: 478px;
                    top: -95px;
                }

                @media (max-width: 767px) {
                    top: -120px;
                }
            }

            @-webkit-keyframes bounce-down {
                25% {
                    -webkit-transform: translateY(-4px);
                }

                50%,
                100% {
                    -webkit-transform: translateY(0);
                }

                75% {
                    -webkit-transform: translateY(4px);
                }
            }

            @keyframes bounce-down {
                25% {
                    transform: translateY(-4px);
                }

                50%,
                100% {
                    transform: translateY(0);
                }

                75% {
                    transform: translateY(4px);
                }
            }

            @-webkit-keyframes bounce-up {
                75% {
                    -webkit-transform: translateY(-10px);
                }

                50%,
                100% {
                    -webkit-transform: translateY(0);
                }

                25% {
                    -webkit-transform: translateY(10px);
                }
            }

            @keyframes bounce-up {
                75% {
                    transform: translateY(-10px);
                }

                50%,
                100% {
                    transform: translateY(0);
                }

                25% {
                    transform: translateY(10px);
                }
            }
        }
    }
}
