@import './_vars.scss';

.bookpay_joinnow {
    position: relative;
    padding: 6.3rem 0;
    background-image: url(../img/bookpay/joinnow/bp_joinnow_bg.png);
    background-size: 450px 690px;
    background-repeat: no-repeat;
    background-position: left;
    background-position-y: center;
    padding-left: 90px;

    @media (max-width: 1537px) {
        padding: 14rem 0 7rem;
    }

    @media (max-width: 1439px) {
        padding: 7rem 0 7rem;
        margin: 0 auto;
    }

    @media (max-width: 1280px) {
        padding: 6.5rem 0;
    }

    @media (max-width: 1023px) {
        padding: 4.12rem 0 6.55rem;
        background-size: 450px 520px;
    }

    @media (max-width: 767px) {
        padding: 0.6rem 0 4rem;
    }

    .item-top {

        h2 {
            @media (max-width: 1439px) {
                padding-top: 6px;
            }

            @media (max-width: 1023px) {
                padding-top: 9px;
            }
        }

        p {
            @media (max-width: 1023px) {
                padding: 18px 0 43px;
            }

            @media (max-width: 830px) {
                padding: 18px 0 !important;
            }

            @media (max-width: 767px) {
                width: 100% !important;
            }
        }
    }

    .item-counters {
        z-index: 3;

        &:before {
            position: absolute;
            content: ' ';
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../img/bookpay/joinnow/bookpay_join_rectangle.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 0;
            opacity: 0.2;
            z-index: 1;
        }
    }

    .btn-download {
        position: relative;
        z-index: 5;
    }

    .joincounters .grid .item .grid.counters .item-counters {
        @media (max-width: 1537px) {
            padding-top: 187px;
        }

        @media (max-width: 1441px) {
            padding-top: 177px;
        }

        @media (max-width: 1370px) {
            padding-top: 170px;
        }

        @media (max-width: 1023px) {
            padding-top: 75px;
        }

        @media (max-width: 767px) {
            padding-top: 78px;
            padding-bottom: 0;
        }
    }

    .joincounters .grid .item .grid.counters .item-bloks .grid-bloks {
        grid-template-rows: 169px 165px;

        @media (max-width: 1537px) {
            grid-template-rows: 167px 162px;
        }

        @media (max-width: 1370px) {
            grid-template-rows: 161px 156px;
            grid-gap: 15px 15px;
        }

        @media (max-width: 1023px) {
            grid-template-rows: 100px 105px;
        }

        @media (max-width: 767px) {
            grid-template-rows: 103px 105px;
        }

        .item {

            &.trusted {
                padding: 42px 0 0 39px;

                @media (max-width: 1537px) {
                    padding: 43px 0 0 39px;
                }

                @media (max-width: 1441px) {
                    padding: 42px 0 0 39px;
                }

                @media (max-width: 1023px) {
                    padding: 22px 0 27px 21px;
                }

                @media (max-width: 767px) {
                    padding: 19px 0 27px 21px;
                }

                background: $primary_black;
                border: 1px solid $grey_light-border;
                border-radius: 15px;

                h4 {
                    color: $yellow;
                    padding-top: 16px;

                    @media (max-width: 1370px) {
                        font-size: 1.563rem;
                    }

                    @media (max-width: 1023px) {
                        font-size: 16px;
                        line-height: 19px;
                        padding-top: 10px
                    }
                }
            }

            &.secure {

                padding: 44px 0 0 39px;

                @media (max-width: 1439px) {
                    padding: 45px 39px 37px;
                }

                @media (max-width: 1023px) {
                    padding: 16px 0 27px 21px;
                }

                background: $grey_dark-border;
                border-radius: 15px;

                p {
                    padding-top: 11px;
                }

                h4 {
                    @media (max-width: 1370px) {
                        font-size: 1.563rem;
                    }

                    @media (max-width: 1023px) {
                        font-size: 16px;
                        line-height: 19px;
                        padding-top: 10px
                    }
                }
            }

            p {
                @media (max-width: 1920px) {
                    @include font-size(1.375, 1.318, 0.02);
                }

                @media (max-width: 1023px) {
                    font-size: 18px;
                }

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 29px;
                }
            }
        }
    }
}