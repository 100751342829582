@import './_vars.scss';

.insurance_reviews {
  position: relative;
  background-image: url(../img/insurance/reviews/InsReviewsBg.png);
  background-size: 596px 520px;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: bottom;
  padding: 4.9rem 0 7.2rem;

  @media (max-width: 1537px) {
    padding: 8.3rem 0 9rem;
  }

  @media (max-width: 1439px) {
    padding: 4.45rem 0 7rem;
  }

  @media (max-width: 1280px) {
    padding: 6.5rem 0;
  }

  @media (max-width: 1023px) {
    padding: 3.1rem 0 6.55rem;
  }

  @media (max-width: 767px) {
    padding: 2.9rem 0 2rem;
  }

  .background-line {
    position: absolute;
    visibility: visible;
    top: 150px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 0;

    @media (max-width: 1479px) {
      top: 140px;
    }

    @media (max-width: 1023px) {
      &.desktop-d {
        display: none;
      }

      &.desktop-t {
        display: block;
        top: 58px
      }
    }

    @media (max-width: 767px) {
      &.desktop-t {
        display: none;
      }

      &.desktop-m {
        display: block;
        top: 0
      }
    }
  }

  .path {
    stroke-dasharray: 5;
    -webkit-animation: dash 40s linear;
    animation: dash 40s linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  :not(svg) {
    transform-origin: 0px 0px;
  }

  @keyframes dash {
    100% {
      stroke-dashoffset: 1000;
    }
  }

  .item {
    background-color: $primary_black;

    h5 {
      margin: 10px 0 0;

      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .img-wrap {
    box-sizing: border-box;
    width: auto;
    margin: 9px 0 0 92px;
    display: inline-flex;

    @media (max-width: 1439px) {
      margin: 0 0 0 72px;
    }

    @media (max-width: 1023px) {
      max-height: 36px;
      margin-left: 0;
    }

    @media (max-width: 767px) {}

    img {
      max-height: 69px;
      padding-bottom: 3px;
      width: auto;
      padding: 0 5px;

      @media (max-width: 1439px) {
        max-width: 352px;
        max-height: auto;
      }

      @media (max-width: 1023px) {
        max-width: 236px;
        width: 13%;
      }

      @media (max-width: 767px) {
        max-width: 215px;
      }
    }
  }

  .slick-list {
    @media (max-width: 1023px) {
      height: 425px !important;
    }

    @media (max-width: 767px) {
      height: 380px !important;
    }

    @media (max-width: 460px) {
      height: 400px !important;
    }

    .item {
      @media (max-width: 1023px) {
        min-height: 380px;
      }

      @media (max-width: 767px) {
        min-height: 280px;
        padding: 20px;
      }
    }
  }

  .slick-arrow {
    @media (max-width: 1023px) {
      top: 110%;
    }

    @media (max-width: 767px) {
      top: 101%;
    }
  }

  .slick-dots {
    @media (max-width: 1023px) {
      bottom: -12%;
    }
  }
}