// colors
$main_col: #E5E5E5;
$white: #ffffff;
$black: #000000;
$primary_black: #131518;
$dark_backround: #20242A;
$grey: #8E8E93;
$grey_dark-border: #191D22;
$grey_light-border: #2A3038;
$grey_date: #BCC3CC;
$yellow: #F7B500;
$footer_background: rgba(255, 255, 255, 0.05);

//padding

$pad_lg: 40px;
$pad: 30px;
$pad_md: 20px;
$pad_sm: 15px;

$cursor-size: 0.5rem;

//   Flex-box

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-center-horiz {
    display: flex;
    justify-content: center;
}

// Font

@mixin font-size($font-size, $line-height: 1, $letter-spacing: 0) {
    font-size: $font-size * 1rem;
    line-height: #{$line-height}em;
    letter-spacing: #{$letter-spacing}em;
}

@mixin border-radius($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin box-shadow($top, $left, $blur, $color) {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
}

@mixin abs-cc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}